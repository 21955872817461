const messages = {
    "certificate-custom-fields": {
        post: [
            {
                identifier: "duplicateentry",
                identifierType: "code",
                messageType: "error",
                message: "Custom field already exists.",
            },
        ],
        put: [
            {
                identifier: "duplicateentry",
                identifierType: "code",
                messageType: "error",
                message: "Custom field already exists.",
            },
        ],
        get: [
            {
                identifier: "entitynotfounderror",
                identifierType: "code",
                messageType: "error",
                message: "No Custom field are found.",
            },
        ],
        delete: [
            {
                identifier: "cannotdeleterecord",
                identifierType: "code",
                messageType: "message",
                message:
                    "<div> This custom field is being used for one or more certificates in this company. You must remove </div> <div> values in this custom field from all certificates before it can be deleted. </div>",
            },
        ],
    },
    "customer-custom-fields": {
        post: [
            {
                identifier: "duplicateentry",
                identifierType: "code",
                messageType: "error",
                message: "Custom field already exists.",
            },
        ],
        put: [
            {
                identifier: "duplicateentry",
                identifierType: "code",
                messageType: "error",
                message: "Custom field already exists.",
            },
        ],
        get: [
            {
                identifier: "entitynotfounderror",
                identifierType: "code",
                messageType: "error",
                message: "No Custom field are found.",
            },
        ],
        delete: [
            {
                identifier: "cannotdeleterecord",
                identifierType: "code",
                messageType: "message",
                message:
                    "<div> This custom field is being used for one or more customers in this company. You must remove </div>  <div> values in this custom field from all customers before it can be deleted. </div>",
            },
        ],
    },
    regions: {
        post: [
            {
                identifier: "duplicateentry",
                identifierType: "code",
                messageType: "error",
                message: "Region already exists.",
            },
        ],
        put: [
            {
                identifier: "duplicateentry",
                identifierType: "code",
                messageType: "error",
                message: "Region already exists.",
            },
        ],
        delete: [
            {
                identifier: "cannotdeleterecord",
                identifierType: "code",
                messageType: "message",
                message:
                    " is currently being used on one or more certificates. Remove this region from all certificates before deleting it.",
            },
        ],
    },
    "invalid-reasons": {
        post: [
            {
                identifier: "duplicateentry",
                identifierType: "code",
                messageType: "error",
                message: "Duplicate name found. Please choose a different name.",
            },
        ],
        put: [
            {
                identifier: "duplicateentry",
                identifierType: "code",
                messageType: "error",
                message: "Duplicate name found. Please choose a different name.",
            },
        ],
        delete: [
            {
                identifier: "cannotdeleterecord",
                identifierType: "code",
                messageType: "message",
                message: "Invalid reason could not be deleted as it is in use",
            },
            {
                identifier: "cannotdeletesystemrecord",
                identifierType: "code",
                messageType: "error",
                message: "Invalid reason could not be deleted as it is a system exempt reason",
            },
        ],
    },
    "exemption-reasons": {
        post: [
            {
                identifier: "duplicateentry",
                identifierType: "code",
                messageType: "error",
                message: "Exempt reason with same name already exists.",
            },
        ],
        put: [
            {
                identifier: "duplicateentry",
                identifierType: "code",
                messageType: "error",
                message: "Exempt reason with same name already exists.",
            },
            {
                identifier: "duplicateexemptreasontagentry",
                identifierType: "code",
                messageType: "error",
                message: "Exempt reason with same tag already exists.",
            },
        ],
        get: [
            {
                identifier: "entitynotfounderror",
                identifierType: "code",
                messageType: "error",
                message: "No Exempt reason are found.",
            },
        ],
        delete: [
            {
                identifier: "cannotdeleterecord",
                identifierType: "code",
                messageType: "error",
                message: "Exempt reason could not be deleted as it is in use",
            },
            {
                identifier: "cannotdeletesystemrecord",
                identifierType: "code",
                messageType: "error",
                message: "Exempt reason could not be deleted as it is a system exempt reason",
            },
        ],
    },
    "certificate-labels": {
        post: [
            {
                identifier: "duplicateentry",
                identifierType: "code",
                messageType: "error",
                message: "Certificate label with same name already exists.",
            },
        ],
        put: [
            {
                identifier: "duplicateentry",
                identifierType: "code",
                messageType: "error",
                message: "Certificate label with same name already exists.",
            },
        ],
        delete: [
            {
                identifier: "cannotdeleterecord",
                identifierType: "code",
                messageType: "message",
                message:
                    "<div> This label is being used for one or more certificates in this company. You must remove </div> <div>it from all certificates before it can be deleted.</div>",
            },
            {
                identifier: "cannotdeletesystemrecord",
                identifierType: "code",
                messageType: "error",
                message:
                    "Certificate label could not be deleted as it is a system certificate label",
            },
        ],
    },
    "customer-labels": {
        post: [
            {
                identifier: "duplicateentry",
                identifierType: "code",
                messageType: "error",
                message: "Customer label with same name already exists.",
            },
        ],
        put: [
            {
                identifier: "duplicateentry",
                identifierType: "code",
                messageType: "error",
                message: "Customer label with same name already exists.",
            },
        ],
        delete: [
            {
                identifier: "cannotdeleterecord",
                identifierType: "code",
                messageType: "message",
                message:
                    "<div>  This label is being used for one or more customers in this company. You must remove </div>  <div>it from all customers before it can be deleted.</div>",
            },
            {
                identifier: "cannotdeletesystemrecord",
                identifierType: "code",
                messageType: "error",
                message: "Customer label could not be deleted as it is a system customer label",
            },
        ],
    },
    "entity-use-code-mapping": {
        post: [
            {
                identifier: "mapincomingentityusecode",
                identifierType: "code",
                messageType: "error",
                message:
                    "Map Incoming for entity use code is currently enabled for exempt reason, Please deselect map incoming or remove it from exempt reason.",
            },
        ],
        put: [
            {
                identifier: "mapincomingentityusecode",
                identifierType: "code",
                messageType: "error",
                message:
                    "Map Incoming for entity use code is currently enabled for exempt reason, Please deselect map incoming or remove it from exempt reason.",
            },
        ],
        delete: [
            {
                identifier: "cannotdeleterecord",
                identifierType: "code",
                messageType: "error",
                message: "Exempt reason is in use and entity use code mapping cannot be deleted",
            },
        ],
    },
    "exempt-product-categories": {
        post: [
            {
                identifier: "duplicateentry",
                identifierType: "code",
                messageType: "error",
                message: "Category with same name already exists.",
            },
        ],
        put: [
            {
                identifier: "duplicateentry",
                identifierType: "code",
                messageType: "error",
                message: "Category with same name already exists.",
            },
        ],
    },
    customers: {
        get: [
            {
                identifier: "entitynotfounderror",
                identifierType: "code",
                messageType: "error",
                message: "Invalid ID. Unable to determine customer you wish to view.",
            },
        ],
        post: [
            {
                identifier: "duplicateentry",
                identifierType: "code",
                messageType: "error",
                message: "Customer number already exists.",
            },
            {
                identifier: "externalapierror",
                identifierType: "code",
                messageType: "error",
                message: "There is an issue with sync data.",
            },
        ],
        put: [
            {
                identifier: "duplicateentry",
                identifierType: "code",
                messageType: "error",
                message: "Customer number already exists.",
            },
            {
                identifier: "externalapierror",
                identifierType: "code",
                messageType: "error",
                message: "There is an issue with sync data.",
            },
        ],
    },
    certificates: {
        get: [
            {
                identifier: "entitynotfounderror",
                identifierType: "code",
                messageType: "error",
                message: "Invalid ID. Unable to determine certificate you wish to view.",
            },
        ],
    },
    "cover-letters": {
        get: [
            {
                identifier: "entitynotfounderror",
                identifierType: "code",
                messageType: "error",
                message: "Invalid ID. Unable to determine cover letter you wish to view.",
            },
        ],
    },
    "ship-to-zones": {
        delete: [
            {
                identifier: "cannotdeleterecord",
                identifierType: "code",
                messageType: "warning",
                message: "This bill-to customer region can't be deleted",
            },
        ],
    },
};

export default messages;
