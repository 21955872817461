import { ecmRole } from "../features/navigation/Subscription";

export const URLToPageNameMap = {
    "/search/customers": "search-customers",
    "/search/certificates": "search-certificates",
    "/add-customer": "add-customer",
    "/customer/#": "customer-details",
    "/certificate/#": "certificate-details",
    "/import-customers": "import-customers",
    "/search/pending": "incoming-certificates",
    "/review": "review-certificates",
    "/review/#": "review-certificate",
    "/requests": "requested-certificates",
    "/requests/bulkRequests": "requested-certificates-bulk",
    "/requests/singleRequests": "requested-certificates-single",
    "/campaigns/#": "view-certificate-requests",
    "/requests/create": "certificates-request-1",
    "/requests/create/2": "certificates-request-2",
    "/requests/create/3": "certificates-request-3",
    "/requests/request-dialog": "certificate-request",
    "/resources": "resources",
    "/resources/tax-answers": "resources-tax-answers",
    "/resources/tax-laws": "resources-tax-laws",
    "/resources/exempt-entities": "resources-exempt-entities",
    "/resources/certificate-forms": "resources-certificate-forms",
    "/reports/saved-searches": "saved-searches",
    "/reports/failed-api-calls": "failed-api-calls",
    "/settings/general": "settings-general",
    "/settings/certificateRequests": "settings-certificate-requests",
    "/settings/certificateFormsInUse": "settings-certificate-forms-in-use",
    "/settings/automations": "settings-automations",
};

export const StatusDescriptions = {
    E: "Error",
    I: "In process",
    C: "Closed",
    D: "Deleted",
};

export const invalidReasonDialogID = "invalid-reason-dialog";

export const HttpStatusCode = {
    Success: 200,
    NotFound: 404,
    BadRequest: 400,
    NotImplemented: 501,
    InternalServerError: 500,
    Created: 201,
};

export const Messages = {
    saved_Success: "The current search has been successfully saved.",
    saved_failure: "The current search cannot be saved.",
};

export const coverLetterOptions = [
    { label: "Standard Vendor Template", value: "Default", selected: true },
];

export const ClaimedByList = [
    {
        label: "Me",
        value: "1",
    },
    {
        label: "Someone else",
        value: "2",
    },
    {
        label: "Unassigned",
        value: "3",
    },
];

export const EscalatedList = [
    {
        label: "Show only escalated certificates",
        value: "1",
    },
    {
        label: "Exclude escalated certificates",
        value: "2",
    },
];

export const TaxNumberTypeList = [
    {
        label: "Tax ID Number",
        value: ["Tax ID Number", "Tax ID number", "TIN", "Exemption Number/Taxpayer ID"],
        selected: false,
    },
    {
        label: "FEIN",
        value: ["FEIN"],
        selected: false,
    },
    {
        label: "Driver’s license",
        value: ["Driver's License", "Driver's License Number", "DLN", "Driver’s license"],
        selected: false,
    },
    {
        label: "Foreign diplomat number",
        value: ["Foreign diplomat number", "Foreign Diplomat Number", "FDN"],
        selected: false,
    },
];

export const AllowedTaxNumberTypeList = [
    "Tax ID Number",
    "Tax ID number",
    "Exemption Number/Taxpayer ID",
    "FEIN",
    "TIN",
    "Driver's License",
    "Driver's License Number",
    "DLN",
    "Driver’s license",
    "Foreign diplomat number",
    "Foreign Diplomat Number",
    "FDN",
];

export const DefaultCoverLetters = {
    standardRequest: "STANDARD_REQUEST",
    ecmDefault: "Default Cover Letter",
    vemDefault: "Standard Vendor Template",
};

export const roleOptionList = [
    { label: "Administrator", value: ecmRole.CEMAdmin },
    { label: "Manager", value: ecmRole.CEMManager },
    { label: "Certificate Reviewer", value: ecmRole.CEMCertificateReviewer },
    { label: "Viewer", value: ecmRole.CEMViewer },
    { label: "No Access", value: ecmRole.CEMNoAccess },
    { label: "Retail Manager", value: ecmRole.RETManager },
    { label: "Retail Associate", value: ecmRole.RETAssociate },
    { label: "Retail Viewer", value: ecmRole.RETViewer },
    { label: "Pre Validator", value: ecmRole.CEMPreValidator },
];

export const roleKeyValue = {
    [ecmRole.CEMAdmin]: "Administrator",
    [ecmRole.CEMManager]: "Manager",
    [ecmRole.CEMCertificateReviewer]: "Certificate Reviewer",
    [ecmRole.CEMViewer]: "Viewer",
    [ecmRole.CEMNoAccess]: "No Access",
    [ecmRole.RETManager]: "Retail Manager",
    [ecmRole.RETAssociate]: "Retail Associate",
    [ecmRole.RETViewer]: "Retail Viewer",
    [ecmRole.CEMPreValidator]: "Pre Validator",
};

export const documentTypeNames = {
    salesAndUseTax: "Sales and Use Tax",
    federalWithholding: "Federal Withholding",
};

export const countryIds = {
    US: "1",
};

export const BackgroundJobState = {
    Queued: 1,
    Processing: 2,
    Completed: 3,
};
export const vendorPackageDummy = "vendorPackageDummy";

export const customFieldTypes = [
    { label: "Text", value: "text", selected: false },
    { label: "Select", value: "select", selected: false },
    { label: "Multi Select", value: "multi-select", selected: false },
    { label: "Logical", value: "boolean", selected: false },
];

export const allSettings = {
    show_no_cert: { label: "Show customers without certificates", type: "client" },
    show_historical: { label: "Show historical certificates", type: "retailSettingUpdates" },
    show_no_pending: {
        label: "Include certificates that haven't been reviewed yet.",
        type: "client",
    },
    fill_only: { label: "Disable certificate image upload", type: "retailSettingUpdates" },
    upload_form_unavailable: {
        label: "Allow certificate image upload only when a fillable form is not available",
        type: "retailSettingUpdates",
    },
    append_barcode: {
        label: "Append a barcode to new certificates",
        type: "retailSettingUpdates",
    },
    certid_footer: {
        label: "Append the ECM certificate ID to new certificates",
        type: "retailSettingUpdates",
    },
    submit_to_stack: {
        label: "Require review of new certificates(Do not automatically mark as valid)",
        type: "retailSettingUpdates",
    },
    preview: {
        label: "Allow print and preview of certificates",
        type: "retailSettingUpdates",
    },
};
export const avataxAPUrls = ["/search/vendors", "/add-vendor", "/vendor/"];

export const UnitedStates = "United States";

export const Canada = "Canada";

export const customerCodeTag = "customer-code";
export const contactNameTag = "contact-name";
export const customerNameTag = "customer-name";
export const addressLine1Tag = "address-line-1";
export const addressLine2Tag = "address-line-2";
export const cityTag = "city";
export const emailAddressTag = "email-address";
export const zipCodeTag = "postal-code";
export const documentTypeOutgoing = false;

export const SalesAndUseTax = "Sales and Use Tax";

export const pendingCertificateColumnPreference = "pending-certificate-columns";

export const PendingStatuses = ["PENDING", "PENDING-SINGLE", "PENDING-MULTI"];

export const CertificateStatuses = {
    Complete: "COMPLETE",
    PendingFuture: "PENDING-FUTURE",
    Pending: "PENDING",
};

export const AlertType = {
    Warning: "warning",
    Info: "info",
    Success: "success",
    Error: "error",
};

export const TaxIdAlertReason = {
    RegexTest: "RegexTest",
    Idvalidation: "IdValidation",
    ValidationNotSupported: "OnlineValidationNotSupported",
};

export const RequestStatuses = {
    EXPIRING: "Expiring",
    EXPIRED: "Expired",
    VALID: "Valid",
    INVALID: "Invalid",
    MISSING: "Missing",
    PENDING: "Pending",
};

export const environments = {
    local: "local",
    dev: "dev",
    qa: "qa",
    sbx: "sbx",
    prd: "prd",
};

export const lowerEnvironments = [environments.local, environments.dev, environments.qa];
export const upperEnvironments = [environments.sbx, environments.prd];

/**
 * Delay in milliseconds for type ahead search
 * @type {{
 *   CustomerQuickSearch: number,
 *   CustomerSearchGrid: number,
 *   PendingCertificateSearchGrid: number,
 *   VendorSearchGrid: number,
 *   VendorCertificateSearchGrid: number,
 *   CertificateSearchGrid: number
 * }}
 */
export const typeAheadDelay = {
    CustomerSearchGrid: 600,
    CustomerQuickSearch: 600,
    VendorSearchGrid: 600,
    VendorCertificateSearchGrid: 600,
    VendorPackageSearchGrid: 600,
    CertificateSearchGrid: 600,
    PendingCertificateSearchGrid: 600,
};

export const StatementOfTaxability = "Statement Of Taxability";
export const Taxable = "taxable";
export const countryInitials = {
    US: "US",
    CA: "CA",
};
export const CRUDOperation = {
    ReadAll: "ReadAll",
    Read: "Read",
    Create: "Create",
    Update: "Update",
    Delete: "Delete",
};
export const UnhandledError = "UnhandledError";
export const ACTIVITY_STORAGE_KEY = "user_last_activity";
export const UnkownError = "Something went wrong";
