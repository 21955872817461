import React, { useEffect, useState } from "react";
import { SBox, SRow, SCol } from "@avalara/skylab-react";
import "./assets/error.css";
import { useDispatch } from "react-redux";
import boxIcon from "./assets/images/Box.svg";
import shopIcon from "./assets/images/Shop.svg";
import computerIcon from "./assets/images/Computer.svg";
import { vendorExemptions } from "../../shared/sessionUtility";

const ErrorPage = React.memo(props => {
    const dispatch = useDispatch();
    const [notFound, hideNotFound] = useState(true);
    const [systemError, hideSystemError] = useState(true);
    const [defaultError, hideDefaultError] = useState(true);
    const [bckImg, setbckImg] = useState("dl-500-background");
    const [noCustomerVendor, hideNoCustomerVendor] = useState(true);
    const [customerOrVendor, setCustomerOrVendor] = useState("customer");
    const [iconImg, seticonImg] = useState(computerIcon);

    const { setHideHeader } = props;

    useEffect(() => {
        if (setHideHeader) setHideHeader(true);
        return () => {
            if (setHideHeader) setHideHeader(false);
        };
    }, [setHideHeader]);

    const isvendorExemptions = dispatch(vendorExemptions());

    useEffect(() => {
        switch (props.type) {
            case 400:
                setbckImg("dl-400-background");
                if (window.location.pathname.startsWith("/customer")) {
                    hideNoCustomerVendor(false);
                    seticonImg(shopIcon);
                } else if (window.location.pathname.startsWith("/vendor") && isvendorExemptions) {
                    hideNoCustomerVendor(false);
                    setCustomerOrVendor("vendor");
                    seticonImg(shopIcon);
                } else {
                    hideDefaultError(false);
                }
                break;
            case 404:
                setbckImg("dl-400-background");
                hideNotFound(false);
                seticonImg(boxIcon);
                break;
            case 500:
                hideSystemError(false);
                break;
            default:
                hideDefaultError(false);
                break;
        }
    }, [props.type]);

    const support = (
        <span>
            <p>
                If problem persists, contact
                <a className="pad-left-xs" href="https://www.avalara.com/us/en/about/support.html">
                    Avalara support
                </a>
            </p>
        </span>
    );
    const searchRedirect = () => {
        window.location.href = `/search/${customerOrVendor}s`;
    };

    return (
        <div className={`flex dl-flex-fill-height dl-scroll ${bckImg}`}>
            <SRow>
                <SCol center className="margin-top-xl">
                    <SBox>
                        <div className="pad-all-lg" id="errorMessage">
                            <span>
                                <img src={iconImg} alt="Not Found" className="pad-right-lg" />
                            </span>
                            <span hidden={notFound} className="float-right pad-top-lg">
                                <h1 className="margin-all-none font-bold">
                                    We can&apos;t find that page
                                </h1>
                                <p className="text-md margin-top-lg">
                                    The page may have been moved, renamed, or deleted.
                                    <br />
                                </p>
                                <span>
                                    <a className="pad-right-xs" href="https://www.avalara.com">
                                        Go to the homepage
                                    </a>
                                    or use the navigation menu to find <br /> where you want to go
                                </span>
                            </span>
                            <span hidden={noCustomerVendor} className="float-right pad-top-lg">
                                <h1 className="margin-all-none font-bold">
                                    We can&apos;t find that {customerOrVendor}
                                </h1>
                                <p className="text-md margin-top-lg">
                                    Let&apos;s find what you&apos;re looking for.
                                    <br />
                                </p>
                                <button
                                    onClick={searchRedirect}
                                    type="button"
                                    className="primary inline">
                                    Search for a {customerOrVendor}
                                </button>
                            </span>
                            <div hidden={systemError} className="float-right pad-top-lg">
                                <span>
                                    <h1 className="margin-all-none font-bold">
                                        It&apos;s not you, it&apos;s us
                                    </h1>
                                    <p className="text-md margin-top-lg">
                                        We&apos;re having a connection problem. <br /> Wait a few
                                        minutes and please try again
                                    </p>
                                </span>
                                {support}
                            </div>
                            <div hidden={defaultError} className="float-right pad-top-lg">
                                <span>
                                    <h1 className="margin-all-none font-bold">
                                        We&apos;ve run into a little glitch
                                    </h1>
                                    <p className="text-md margin-top-lg">
                                        We are looking into what happened so we can fix it.
                                        <br /> Wait a few minutes and please try again.
                                    </p>
                                </span>
                                {support}
                            </div>
                        </div>
                        <div className="text-right text-xs" hidden={props.traceId == null}>
                            Trace ID: {props.traceId}
                        </div>
                    </SBox>
                </SCol>
            </SRow>
        </div>
    );
});

export default ErrorPage;
