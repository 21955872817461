import React, { useState, useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { SRow, SCol, SIcon, SDatepicker } from "@avalara/skylab-react";
import SelectState from "../../sharedComponents/SelectState";
import SelectExposureZone from "../../sharedComponents/SelectExposureZone";
import SelectCustomerLabel from "../../sharedComponents/SelectCustomerLabel";
import { selectSetting } from "../../../app/settingsSlice";
import FeatureToggler from "../../../featureToggler/FeatureToggler";
import CustomerCustomField from "./CustomerCustomFieldFilter";
import { errorTag, noErrorTag } from "../../../shared/Utils";

const FilterByCustomer = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const {
        isLoading,
        updateFilters,
        handleCheckBoxChange,
        handleMultilineInputChange,
        filterState,
        setFilterState,
        usedBy,
    } = props;

    const {
        isCustomerDetails,
        customerRegions,
        customersLabels,
        isMissingEmailAddress,
        isMissingcertificates,
        missingExposureZones,
        isBillToRelation,
        isShipToRelation,
        isDuplicateRelation,
        customerCodes,
        customerCodeError,
        customerCreatedDateAfter,
        customerCreatedDateBefore,
        customerCreatedDateAfterError,
        customerCreatedDateBeforeError,
        customerModifiedDateAfter,
        customerModifiedDateBefore,
        customerModifiedDateAfterError,
        customerModifiedDateBeforeError,
        customerCustomFields,
        alternateId,
    } = filterState;

    const [customerCodePrevVal, setCustomerCodePrevVal] = useState("");
    const [customerRegionPrevVal, setCustomerRegionPrevVal] = useState([]);
    const [customerLabelPrevVal, setCustomerLabelPrevVal] = useState([]);
    const [missingExposureZonePrevVal, setMissingExposureZonePrevValPrevVal] = useState([]);
    const settings = useSelector(selectSetting, shallowEqual);

    const handleSearch = async (id, value) => {
        dispatch(
            setFilterState({
                [id]: [...filterState[`${id}`], value.value],
            })
        );
    };

    const handleRemove = async (selectKey, optionObj, isDeselectAll) => {
        switch (selectKey) {
            case "customerRegions": {
                setCustomerRegionPrevVal(
                    customerRegionPrevVal?.filter(val => val !== optionObj?.value)
                );
                break;
            }
            case "missingExposureZones": {
                if (isDeselectAll) {
                    setMissingExposureZonePrevValPrevVal([]);
                } else {
                    setMissingExposureZonePrevValPrevVal(
                        missingExposureZonePrevVal?.filter(val => val !== optionObj?.value)
                    );
                }
                break;
            }
            case "customersLabels": {
                if (isDeselectAll) {
                    setCustomerLabelPrevVal([]);
                } else {
                    setCustomerLabelPrevVal(
                        customerLabelPrevVal?.filter(val => val !== optionObj?.value)
                    );
                }
                break;
            }
            default:
                break;
        }
        if (isDeselectAll) {
            dispatch(setFilterState({ [`${selectKey}`]: [] }));
        } else {
            dispatch(
                setFilterState({
                    [`${selectKey}`]:
                        selectKey === "missingExposureZones" && isDeselectAll
                            ? []
                            : filterState[`${selectKey}`]?.filter(
                                  option => option !== optionObj?.value
                              ),
                })
            );
        }
    };

    const handleSelectClose = async (fieldId, e) => {
        switch (fieldId) {
            case "customerRegions": {
                setCustomerRegionPrevVal(e.target.value);
                break;
            }
            case "missingExposureZones": {
                setMissingExposureZonePrevValPrevVal(e.target.value);
                break;
            }
            case "customersLabels": {
                setCustomerLabelPrevVal(e.target.value);
                break;
            }
            default:
                break;
        }
    };

    const handleMissingcertificatesChange = useCallback(
        async e => {
            await dispatch(
                setFilterState({
                    [e.target.id]: e.target.checked,
                })
            );
            if (!e.target.checked) {
                await dispatch(
                    setFilterState({
                        missingExposureZones: [],
                    })
                );
            }
        },
        [dispatch, setFilterState]
    );

    const updateCustomFieldPostData = async (customFieldId, customFieldValue) => {
        let value = "";
        if (Array.isArray(customFieldValue)) {
            value = customFieldValue.join("|");
        } else {
            value = customFieldValue;
        }
        let currentCustomFieldData = { ...customerCustomFields };
        if (customFieldValue === "" || customFieldValue === null || customFieldValue.length === 0) {
            delete currentCustomFieldData[customFieldId];
        } else {
            currentCustomFieldData = {
                ...currentCustomFieldData,
                ...{ [customFieldId]: value },
            };
        }
        await dispatch(
            setFilterState({
                customerCustomFields: currentCustomFieldData,
            })
        );
    };

    const handleTextBoxChange = useCallback(
        async e => {
            await dispatch(
                setFilterState({
                    [e.target.id]: e.target.value,
                })
            );
        },
        [dispatch, setFilterState]
    );

    return (
        <div className={isCustomerDetails ? "block" : "hidden"}>
            <SRow>
                <SCol span="12" className="pad-bottom-sm">
                    <SelectState
                        ref={ref}
                        id="customerRegions"
                        name="customerRegions"
                        onAdd={e => handleSearch("customerRegions", e)}
                        onRemove={e => handleRemove("customerRegions", e)}
                        onClose={e => handleSelectClose("customerRegions", e)}
                        loading={isLoading}
                        value={customerRegions}
                        multiple
                        label="Region of customer address"
                        disabled={isLoading}
                    />
                </SCol>
            </SRow>

            <SRow>
                <SCol span="12" className="pad-bottom-none">
                    <input
                        id="isMissingEmailAddress"
                        name="isMissingEmailAddress"
                        type="checkbox"
                        aria-label="Customers missing an email address"
                        onChange={e => handleCheckBoxChange(e)}
                        checked={isMissingEmailAddress}
                        disabled={isLoading}
                    />
                    <label htmlFor="isMissingEmailAddress">
                        Customers missing an email address
                    </label>
                </SCol>
            </SRow>

            <SRow className={usedBy === "CustomerSearch" ? "block" : "hidden"}>
                <SCol span="12" className="pad-bottom-sm">
                    <input
                        id="isMissingcertificates"
                        name="isMissingcertificates"
                        type="checkbox"
                        aria-label="Customers missing certificates"
                        onChange={e => handleMissingcertificatesChange(e)}
                        checked={isMissingcertificates}
                        disabled={isLoading}
                    />
                    <label htmlFor="isMissingcertificates">Customers missing certificates</label>
                </SCol>
                {isMissingcertificates ? (
                    <SCol span="12" className="pad-bottom-sm">
                        <SelectExposureZone
                            id="missingExposureZones"
                            name="missingExposureZones"
                            onAdd={e => handleSearch("missingExposureZones", e)}
                            onRemove={(eventDetail, isDeselectAll) =>
                                handleRemove("missingExposureZones", eventDetail, isDeselectAll)
                            }
                            onClose={e => handleSelectClose("missingExposureZones", e)}
                            loading={isLoading}
                            value={missingExposureZones}
                            multiple
                            exposureZoneDisabled={false}
                            disabled={isLoading}
                        />
                    </SCol>
                ) : null}
            </SRow>

            <SRow hidden={!settings.showCustomerRelationships}>
                <SCol span="12" className="pad-bottom-sm">
                    <div>
                        <input
                            id="isBillToRelation"
                            name="isBillToRelation"
                            type="checkbox"
                            aria-label="Is a bill-to customer"
                            onChange={e => handleCheckBoxChange(e)}
                            checked={isBillToRelation}
                            disabled={isLoading}
                        />
                        <label
                            htmlFor="isBillToRelation"
                            style={{ marginTop: usedBy === "CustomerSearch" ? "0.4rem" : "" }}>
                            Is a bill-to customer
                        </label>
                    </div>
                    <div>
                        <input
                            id="isShipToRelation"
                            name="isShipToRelation"
                            type="checkbox"
                            aria-label="Is a ship-to customer"
                            onChange={e => handleCheckBoxChange(e)}
                            checked={isShipToRelation}
                            disabled={isLoading}
                        />
                        <label htmlFor="isShipToRelation">Is a ship-to customer</label>
                    </div>
                </SCol>
            </SRow>

            <SRow hidden={!settings.showDuplicateCustomers}>
                <SCol span="12" className="pad-bottom-sm">
                    <div>
                        <input
                            id="isDuplicateRelation"
                            name="sDuplicateRelation"
                            type="checkbox"
                            aria-label="Has a duplicate customer relationship"
                            onChange={e => handleCheckBoxChange(e)}
                            checked={isDuplicateRelation}
                            disabled={isLoading}
                        />
                        <label htmlFor="isDuplicateRelation" style={{ marginTop: "0.4rem" }}>
                            Has a duplicate customer relationship
                        </label>
                    </div>
                </SCol>
            </SRow>

            <SRow>
                <SCol span="12" className="pad-bottom-sm">
                    <label htmlFor="customerCodes">Customer code</label>
                    <textarea
                        name="customerCodes"
                        id="customerCodes"
                        rows="5"
                        onChange={handleMultilineInputChange("customerCodes")}
                        onBlur={e => {
                            if (customerCodePrevVal !== e.target.value)
                                setCustomerCodePrevVal(e.target.value);
                        }}
                        value={customerCodes}
                        className={customerCodeError ? errorTag : noErrorTag}
                        disabled={isLoading}
                    />
                    <div className="input-msg">
                        <SIcon name="alert-circle-filled" aria-hidden="true" />
                        {customerCodeError}
                    </div>
                </SCol>
            </SRow>
            <SRow>
                <SCol span="12" className="pad-bottom-sm">
                    <label htmlFor="alternateId">Alternate ID</label>
                    <input
                        name="alternateId"
                        id="alternateId"
                        type="text"
                        onChange={e => handleTextBoxChange(e)}
                        value={alternateId}
                        disabled={isLoading}
                    />
                </SCol>
            </SRow>
            <SRow>
                <SCol span="6" className="pad-right-xs pad-bottom-sm">
                    <label htmlFor="customerCreatedDateAfter">Created starting on</label>
                    <SDatepicker
                        inputid="customerCreatedDateAfter"
                        className="width-auto"
                        disabled={isLoading}
                        onS-select={e => {
                            updateFilters("customerCreatedDateAfter", e.detail.value, false);
                            e.target.className = "";
                        }}
                        value={customerCreatedDateAfter}
                        onS-deselect={() => {
                            updateFilters("customerCreatedDateAfter", "", false);
                        }}
                    />
                    <div className="input-msg">
                        <SIcon name="alert-circle-filled" aria-hidden="true" />
                        {customerCreatedDateAfterError}
                    </div>
                </SCol>
                <SCol span="6" className="pad-left-xs pad-bottom-sm">
                    <label htmlFor="customerCreatedDateBefore">...and through</label>
                    <SDatepicker
                        inputid="customerCreatedDateBefore"
                        className="width-auto"
                        disabled={isLoading}
                        onS-select={e => {
                            updateFilters("customerCreatedDateBefore", e.detail.value, false);
                        }}
                        value={customerCreatedDateBefore}
                        onS-deselect={() => {
                            updateFilters("customerCreatedDateBefore", "", false);
                        }}
                    />
                    <div className="input-msg">
                        <SIcon name="alert-circle-filled" aria-hidden="true" />
                        {customerCreatedDateBeforeError}
                    </div>
                </SCol>
            </SRow>
            <SRow>
                <SCol span="6" className="pad-right-xs pad-bottom-sm">
                    <label htmlFor="customerModifiedDateAfter">Modified starting on</label>
                    <SDatepicker
                        inputid="customerModifiedDateAfter"
                        className="width-auto"
                        disabled={isLoading}
                        onS-select={e => {
                            updateFilters("customerModifiedDateAfter", e.detail.value, false);
                        }}
                        value={customerModifiedDateAfter}
                        onS-deselect={() => {
                            updateFilters("customerModifiedDateAfter", "", false);
                        }}
                    />
                    <div className="input-msg">
                        <SIcon name="alert-circle-filled" aria-hidden="true" />
                        {customerModifiedDateAfterError}
                    </div>
                </SCol>
                <SCol span="6" className="pad-left-xs pad-bottom-sm">
                    <label htmlFor="customerModifiedDateBefore">...and through</label>
                    <SDatepicker
                        inputid="customerModifiedDateBefore"
                        className="width-auto"
                        disabled={isLoading}
                        onS-select={e => {
                            updateFilters("customerModifiedDateBefore", e.detail.value, false);
                        }}
                        value={customerModifiedDateBefore}
                        onS-deselect={() => {
                            updateFilters("customerModifiedDateBefore", "", false);
                        }}
                    />
                    <div className="input-msg">
                        <SIcon name="alert-circle-filled" aria-hidden="true" />
                        {customerModifiedDateBeforeError}
                    </div>
                </SCol>
            </SRow>

            <SRow className={usedBy === "CustomerSearch" ? "block" : "hidden"}>
                <SCol span="12" className="pad-bottom-sm">
                    <FeatureToggler category="customers" id="customFields">
                        <CustomerCustomField
                            updateCustomFieldPostData={updateCustomFieldPostData}
                            loading={isLoading}
                            searchValues={customerCustomFields}
                        />
                    </FeatureToggler>
                </SCol>
            </SRow>
            <SRow>
                <SCol span="12" className="pad-bottom-sm">
                    <FeatureToggler category="customers" id="customerLabels">
                        <SelectCustomerLabel
                            id="customersLabels"
                            name="customersLabels"
                            loading={isLoading}
                            disabled={isLoading}
                            onAdd={e => handleSearch("customersLabels", e)}
                            onRemove={(e, isDeselectAll) => {
                                handleRemove("customersLabels", e, isDeselectAll);
                            }}
                            onClose={e => handleSelectClose("customersLabels", e)}
                            value={customersLabels}
                        />
                    </FeatureToggler>
                </SCol>
            </SRow>
        </div>
    );
});

export default FilterByCustomer;
