import React, { useEffect, useState } from "react";
import { SBox, SRow, SCol } from "@avalara/skylab-react";
import "./assets/error.css";
import keyIcon from "./assets/images/Keys.svg";
import backgroundNoAccess from "./assets/images/BackgroundNoAccess.svg";

const NoAccessErrorPage = React.memo(props => {
    const [bckImg, setbckImg] = useState(backgroundNoAccess);
    const [iconImg, seticonImg] = useState(keyIcon);

    const { setHideHeader } = props;
    useEffect(() => {
        if (setHideHeader) setHideHeader(true);
        return () => {
            if (setHideHeader) setHideHeader(false);
        };
    }, [setHideHeader]);

    useEffect(() => {
        setbckImg("dl-noaccess-background");
        seticonImg(keyIcon);
    }, [props.type]);

    return (
        <div className={`flex dl-flex-fill-height dl-scroll ${bckImg}`}>
            <SRow>
                <SCol center className="box-css">
                    <SBox>
                        <div className="pad-all-lg" id="errorMessage">
                            <span>
                                <img src={iconImg} alt="Not Found" className="pad-right-lg" />
                            </span>
                            <span className="float-right">
                                <h1 className="font-bold">
                                    <span>You don&apos;t have permission to</span>
                                    <br />
                                    <span className="header-text">view this page</span>
                                </h1>
                                <span>
                                    Your account administrator can update your permissions. If you
                                    <br /> think this message is appearing by mistake,{" "}
                                    <a
                                        className="pad-right-xs"
                                        href="https://www.avalara.com/us/en/about/support.html">
                                        contact support
                                    </a>
                                </span>
                            </span>
                        </div>
                        <div className="text-right text-xs" hidden={props.traceId == null}>
                            Trace ID: {props.traceId}
                        </div>
                    </SBox>
                </SCol>
            </SRow>
        </div>
    );
});

export default NoAccessErrorPage;
