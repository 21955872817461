/* eslint-disable import/prefer-default-export */

import { subscription } from "../features/navigation/Subscription";

/**
 * CERTCAP-65098
 * Checks if customer address is required based on the account subscriptions.
 */
export const isCustomerAddressRequired = () => (dispatch, getState) => {
    const { session } = getState();
    const { subscriptions } = session;

    return !!hasAvaTaxSubscriptions(subscriptions);
};

const hasAvaTaxSubscriptions = subscriptions => {
    return (
        subscriptions.includes(subscription.AVAGLOBAL) ||
        (subscriptions.includes(subscription.AVATAXST) &&
            subscriptions.includes(subscription.AVATAXPRO))
    );
};
