import { SCol, SIcon, SRow } from "@avalara/skylab-react";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { selectDefaultCountryId } from "../../../app/certificateSlice";
import {
    fetchCustomerAPI,
    getStateByCountry,
    selectCustomer,
    selectLoading,
    setCustomer,
} from "../../../app/customerSlice";
import { selectSelectedLocation } from "../../../app/retailSlice";
import toast from "../../../hooks/toast";
import { errorTag, noErrorTag, urlToPageMap } from "../../../shared/Utils";
import { parseSuccessResponse, parsePostObjResponse } from "../../../shared/responseUtils";
import {
    addressLine1Tag,
    addressLine2Tag,
    Canada,
    cityTag,
    contactNameTag,
    customerCodeTag,
    customerNameTag,
    emailAddressTag,
    UnitedStates,
    zipCodeTag,
    CRUDOperation,
} from "../../../shared/constants";
import { checkValidation, postCustomerAPI, putCustomerAPI } from "../../../shared/customerUtility";
import ValidationError from "../../sharedComponents/ValidationError";
import SelectState from "../../customers/SelectState";
import NoData from "../../search/NoData";
import SelectCountry from "../../sharedComponents/SelectCountry";
import "../search/search.css";
import "./customer.css";

function CustomerForm(props) {
    const dispatch = useDispatch();
    const params = useParams();

    const defaultCountryId = useSelector(selectDefaultCountryId);
    const customer = useSelector(selectCustomer, shallowEqual);
    const isLoading = useSelector(selectLoading);
    const selectedLocation = useSelector(selectSelectedLocation, shallowEqual);
    const [showToast] = toast();
    const navigate = useNavigate();

    const initialState = {
        customerCode: customer.customerNumber || "",
        customerName: customer.name || "",
        countryId: customer.country?.id || "",
        countryName: customer.country?.name || "",
        state: customer.state?.id || "",
        addressLine1: customer.addressLine1 || "",
        addressLine2: customer.addressLine2 || "",
        city: customer.city || "",
        zipCode: customer.zip || "",
        phoneNumber: customer.phoneNumber || "",
        emailAddress: customer.emailAddress || "",
        contactName: customer.contactName || "",
    };

    if (!customer.name) {
        initialState.country = defaultCountryId;
    }

    const [customerCode, setCustomerCode] = useState(initialState.customerCode);
    const [customerCodeError, setCustomerCodeError] = useState("");
    const [customerName, setCustomerName] = useState(initialState.customerName);
    const [customerNameError, setCustomerNameError] = useState("");
    const [countryId, setCountryId] = useState(initialState.countryId);
    const [countryName, setCountryName] = useState(initialState.countryName);
    const [countryError, setCountryError] = useState(noErrorTag);
    const [addressLine1, setAddressLine1] = useState(initialState.addressLine1);
    const [addressLine1ErrorMsg, setAddressLine1ErrorMsg] = useState("");
    const [addressLine2, setAddressLine2] = useState(initialState.addressLine2);
    const [city, setCity] = useState(initialState.city);
    const [cityError, setCityError] = useState("");
    const [state, setState] = useState(initialState.state);
    const [stateError, setStateError] = useState("");
    const [zipCode, setZipCode] = useState(initialState.zipCode);
    const [zipCodeError, setZipCodeError] = useState("");
    const [phoneNumber, setPhoneNumber] = useState(initialState.phoneNumber);
    const [emailAddress, setEmailAddress] = useState(initialState.emailAddress);
    const [contactName, setContactName] = useState(initialState.contactName);
    const [emailError, setEmailError] = useState("");
    const [showAddressLine2, setShowAddressLine2] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

    useEffect(() => {
        props.setHideNav(true);
        return () => props.setHideNav(false);
    }, [props.setHideNav]);

    useEffect(() => {
        if (countryId) {
            dispatch(getStateByCountry(countryId));
        } else if (defaultCountryId) {
            dispatch(getStateByCountry(defaultCountryId));
        }
    }, [dispatch, countryId, defaultCountryId]);

    useEffect(() => {
        if (Number.isInteger(parseInt(params?.id, 10))) {
            dispatch(fetchCustomerAPI(params.id));
        }
    }, [params?.id]);

    useEffect(() => {
        setCustomerCode(customer.customerNumber || "");
        setCustomerName(customer.name || "");
        setCountryId(customer.country?.id || "");
        setCountryName(customer.country?.name || "");
        setAddressLine1(customer.addressLine1 || "");
        setAddressLine2(customer.addressLine2 || "");
        if (customer.addressLine2) setShowAddressLine2(true);
        setCity(customer.city || "");
        setState(customer.state?.id || null);
        setZipCode(customer.zip || "");
        setPhoneNumber(customer.phoneNumber || "");
        setEmailAddress(customer.emailAddress || "");
        setContactName(customer.contactName || "");
    }, [
        customer.customerNumber,
        customer.name,
        customer.country?.name,
        customer.country?.id,
        customer.state?.id,
        customer.addressLine1,
        customer.addressLine2,
        customer.city,
        customer.zip,
        customer.phoneNumber,
        customer.emailAddress,
        customer.contactName,
    ]);

    useEffect(() => {
        return () => dispatch(setCustomer({}));
    }, [dispatch]);

    const onViewMode = !!params?.id;
    const onAddMode = !onViewMode;
    const noCustomerFound = !onAddMode && !customer?.customerNumber;

    const saveButton = classnames({
        primary: true,
        large: true,
        loading: submitting,
        "max-width-buttton": true,
        "pad-all-none": true,
    });

    const cancelButton = classnames({
        tertiary: true,
        large: true,
        "max-width-buttton": true,
    });

    const handleChangeInput = setter => {
        return event => {
            setIsSaveButtonDisabled(false);
            if (event.target.value && event.target.className === "error") {
                if (event.target.name === customerCodeTag) {
                    setCustomerCodeError("");
                }
                if (event.target.name === customerNameTag) {
                    setCustomerNameError("");
                }
                if (event.target.name === addressLine1Tag) {
                    setAddressLine1ErrorMsg("");
                }
                if (event.target.name === cityTag) {
                    setCityError("");
                }
                if (event.target.name === emailAddressTag) {
                    setEmailError("");
                }
                if (event.target.name === zipCodeTag) {
                    setZipCodeError("");
                }
            }
            setter(event.target.value);
        };
    };

    const handleCountryChange = (value, detail) => {
        setIsSaveButtonDisabled(false);
        setCountryId(value);
        setCountryName(detail.item.label);
        setState(null);
        setCountryError("valid");
        dispatch(getStateByCountry(value));
    };

    const handleCountryRemove = () => {
        setIsSaveButtonDisabled(false);
        setCountryId(null);
        setState(null);
        setCountryError("valid");
    };

    const handleStateChange = value => {
        setIsSaveButtonDisabled(false);
        setState(value.value);
        setStateError("");
    };

    const handleStateRemove = () => {
        setIsSaveButtonDisabled(false);
        setState("");
        setStateError("");
    };

    const data = {
        customerCode,
        customerName,
        countryId,
        countryName,
        addressLine1,
        city,
        state,
        zipCode,
        phoneNumber,
        emailAddress,
    };

    const handleSubmitForm = async event => {
        event.preventDefault();
        const validationSuccess = checkValidation(data);
        if (validationSuccess.valid) {
            setSubmitting(true);

            const customerData = {
                customerNumber: customerCode?.trim(),
                name: customerName?.trim(),
                addressLine1: addressLine1?.trim(),
                addressLine2: addressLine2?.trim(),
                country: { id: countryId || defaultCountryId },
                city: city?.trim(),
                zip: zipCode?.trim(),
                contactName: contactName?.trim(),
                phoneNumber: phoneNumber?.trim(),
                emailAddress: emailAddress?.trim(),
            };
            if (state) {
                customerData.state = { id: state };
            }

            if (customer.id) {
                const updatedCustomerResponse = await putCustomerAPI(customerData, customer);
                if (
                    parseSuccessResponse(CRUDOperation.Update, updatedCustomerResponse)?.isSuccess
                ) {
                    showToast("success", `Successfully updated customer.`, "customer-success");
                    navigate(-1);
                } else {
                    showToast(
                        "error",
                        `Could not update customer. Try again later.`,
                        "customer-success"
                    );
                }
            } else {
                // set location id only while creating customer
                if (selectedLocation != null && selectedLocation.value !== "-1")
                    customerData.locationId = parseInt(selectedLocation.value, 10);
                const returnData = await postCustomerAPI(customerData);
                if (parseSuccessResponse(CRUDOperation.Create, returnData)?.isSuccess) {
                    showToast("success", `Successfully added customer.`, "customer-success");
                    const addedCustomer = parsePostObjResponse(returnData);
                    navigate(
                        `${urlToPageMap.retail}/${urlToPageMap.retailCustomer.replace(
                            ":id?",
                            addedCustomer?.id
                        )}/${urlToPageMap.retailAddCertificate}`
                    );
                } else {
                    showToast(
                        "error",
                        `Could not create customer. Try again later.`,
                        "customer-success"
                    );
                }
            }
            setSubmitting(false);
        } else {
            const { errorFields } = validationSuccess;
            if (errorFields.email) setEmailError("Please enter a valid email address");
            if (errorFields.customerCode) setCustomerCodeError(errorTag);
            if (errorFields.customerName) setCustomerNameError(errorTag);
            if (errorFields.country) setCountryError("error");
            if (errorFields.addressLine1.addressLine1Empty)
                setAddressLine1ErrorMsg("Enter an address");
            if (errorFields.addressLine1.addressLine1Length)
                setAddressLine1ErrorMsg("The address can only contain 50 characters");
            if (errorFields.city) setCityError(errorTag);
            if (errorFields.state) setStateError(errorTag);
            if (errorFields.zipCode.required) setZipCodeError("Enter a zip code");
            else if (errorFields.zipCode[UnitedStates])
                setZipCodeError("Zip length must be between 5 and 10 characters");
            else if (errorFields.zipCode[Canada])
                setZipCodeError("Zip length must be between 6 and 10 characters");
        }
    };

    let dom;
    if (isLoading) {
        dom = (
            <div className="flex dl-flex-fill-height margin-top-md">
                <h3>Loading customer...</h3>
            </div>
        );
    } else if (noCustomerFound) {
        dom = <NoData retailCustomerForm />;
    } else {
        dom = (
            <form onSubmit={handleSubmitForm}>
                {onAddMode && (
                    <SRow className="margin-top-sm">
                        <SCol className="font-semibold text-md">
                            Start by creating the customer
                        </SCol>
                    </SRow>
                )}
                <SRow className="margin-top-md">
                    <SCol span={6}>
                        <label htmlFor="customer-name" className="required">
                            Customer name
                        </label>
                        <input
                            type="text"
                            id="customer-name"
                            name="customer-name"
                            className={customerNameError ? errorTag : noErrorTag}
                            value={customerName}
                            onChange={handleChangeInput(setCustomerName)}
                        />
                        <ValidationError fieldName="customer name" />
                    </SCol>
                    <SCol span={6}>
                        <label htmlFor={customerCodeTag} className="required">
                            Customer code
                        </label>
                        <input
                            type="text"
                            id={customerCodeTag}
                            name={customerCodeTag}
                            value={customerCode}
                            onChange={handleChangeInput(setCustomerCode)}
                            className={customerCodeError ? errorTag : noErrorTag}
                        />
                        <ValidationError fieldName="customer code" />
                    </SCol>
                </SRow>
                <SRow className="margin-top-md">
                    <SCol span={12}>
                        <SelectCountry
                            all
                            required
                            onChange={handleCountryChange}
                            onRemove={handleCountryRemove}
                            value={countryId}
                            countryError={countryError}
                        />
                        <ValidationError fieldName="country" />
                    </SCol>
                </SRow>
                <SRow className="margin-top-md">
                    <SCol span={12}>
                        <SRow>
                            <SCol span={12}>
                                <label htmlFor={addressLine1Tag} className="required">
                                    Address
                                </label>
                                <input
                                    type="text"
                                    id={addressLine1Tag}
                                    name={addressLine1Tag}
                                    value={addressLine1}
                                    className={addressLine1ErrorMsg ? errorTag : noErrorTag}
                                    onChange={handleChangeInput(setAddressLine1)}
                                />
                                <ValidationError fieldName="address" />
                                {!showAddressLine2 ? (
                                    <div className="margin-top-md">
                                        <button
                                            className="link"
                                            onClick={() => setShowAddressLine2(true)}>
                                            <SIcon
                                                name="plus"
                                                className="custom-top-icon"
                                                aria-hidden="true"
                                            />
                                            <span className="margin-left-xs">Address line</span>
                                        </button>
                                    </div>
                                ) : null}
                            </SCol>
                        </SRow>
                        {showAddressLine2 ? (
                            <SRow class="margin-top-md">
                                <SCol span={12}>
                                    <label htmlFor={addressLine2Tag}>Address Line 2</label>
                                    <input
                                        type="text"
                                        id={addressLine2Tag}
                                        name={addressLine2Tag}
                                        value={addressLine2}
                                        onChange={handleChangeInput(setAddressLine2)}
                                    />
                                </SCol>
                            </SRow>
                        ) : null}
                    </SCol>
                </SRow>
                <SRow class="margin-top-md">
                    <SCol span={4}>
                        <label htmlFor={cityTag} className="required">
                            City
                        </label>
                        <input
                            type="text"
                            id={cityTag}
                            name={cityTag}
                            value={city}
                            onChange={handleChangeInput(setCity)}
                            className={cityError ? errorTag : noErrorTag}
                        />
                        <ValidationError fieldName="city" />
                    </SCol>
                    <SCol span={4}>
                        <SelectState
                            required
                            value={state}
                            onChange={handleStateChange}
                            onRemove={handleStateRemove}
                            stateError={stateError || noErrorTag}
                            label="State, province, or territory"
                        />
                        <ValidationError fieldName="region" />
                    </SCol>
                    <SCol span={4}>
                        <label htmlFor={zipCodeTag} className="required">
                            ZIP code
                        </label>
                        <input
                            type="text"
                            id={zipCodeTag}
                            name={zipCodeTag}
                            value={zipCode}
                            className={zipCodeError ? errorTag : noErrorTag}
                            onChange={handleChangeInput(setZipCode)}
                        />
                        <ValidationError fieldName={zipCodeTag} errorMsg={zipCodeError} />
                    </SCol>
                </SRow>
                <SRow class="margin-top-md">
                    <SCol span={12}>
                        <SRow>
                            <SCol span={6} class="text-sm-strong">
                                Contact
                            </SCol>
                        </SRow>
                        <SRow>
                            <SCol span={6}>
                                <label htmlFor={contactNameTag}>Name of contact</label>
                                <input
                                    type="text"
                                    id={contactNameTag}
                                    value={contactName}
                                    onChange={handleChangeInput(setContactName)}
                                />
                            </SCol>
                        </SRow>
                        <SRow class="margin-top-sm">
                            <SCol span={6}>
                                <label htmlFor="phone-number">Phone number</label>
                                <input
                                    type="text"
                                    id="phone-number"
                                    value={phoneNumber}
                                    onChange={handleChangeInput(setPhoneNumber)}
                                />
                            </SCol>
                            <SCol span={6}>
                                <label htmlFor="email-address">Email address</label>
                                <input
                                    type="text"
                                    id="email-address"
                                    name="email-address"
                                    value={emailAddress}
                                    onChange={handleChangeInput(setEmailAddress)}
                                    className={emailError ? errorTag : noErrorTag}
                                />
                                <ValidationError fieldName="email-address" errorMsg={emailError} />
                            </SCol>
                        </SRow>
                    </SCol>
                </SRow>
                <SRow class="margin-top-xl">
                    <SCol span="xs-6 sm-6 4">
                        <button
                            className={saveButton}
                            type="submit"
                            onClick={handleSubmitForm}
                            disabled={isSaveButtonDisabled}>
                            {customer?.id ? "Save" : "Save and continue"}
                        </button>
                    </SCol>
                    <SCol span="xs-6 sm-6 4">
                        <button
                            type="button"
                            className={cancelButton}
                            onClick={() => {
                                navigate(-1);
                            }}>
                            Cancel
                        </button>
                    </SCol>
                </SRow>
            </form>
        );
    }

    return dom;
}

export default CustomerForm;
