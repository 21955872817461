import { SCol, SRow } from "@avalara/skylab-react";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { selectColSpan } from "../../app/retailSlice";

function Retail(props) {
    const colSpan = useSelector(selectColSpan);

    useEffect(() => {
        props.setHideNav(true);
        return () => props.setHideNav(false);
    }, [props.setHideNav]);

    return (
        <SRow>
            <SCol span={colSpan} center="md">
                <Outlet />
                <span />
            </SCol>
        </SRow>
    );
}

export default Retail;
