import React, { useEffect, useState, Suspense } from "react";
import classnames from "classnames";
import { SBox, SLoader } from "@avalara/skylab-react";
import ZoomTip from "../../sharedComponents/ZoomTip";

const CertificateOverviewGraph = React.lazy(() =>
    import("../../sharedComponents/CertificateOverviewGraph")
);

const ActiveCertificates = React.memo(props => {
    const [overview, setOverview] = useState({});
    useEffect(() => {
        let overviewData = props?.overviewData ?? JSON.parse(props?.overviewData);
        const overviewMissingCertificates = props.overviewMissingCertificatesData;
        if (
            overviewData &&
            overviewMissingCertificates &&
            Object.keys(overviewData).length > 0 &&
            Object.keys(overviewMissingCertificates).length > 0
        ) {
            const invalidCertificateCount = overviewData.invalidCertificateCount
                ? overviewData.invalidCertificateCount
                : 0;
            const missingCertificateCount = overviewMissingCertificates.missingCertificateCount
                ? overviewMissingCertificates.missingCertificateCount
                : 0;
            const expiredCertificateCount = overviewData.expiredCertificateCount
                ? overviewData.expiredCertificateCount
                : 0;
            const needAttentionCount =
                missingCertificateCount + invalidCertificateCount + expiredCertificateCount;
            const validCount = overviewData.validCount ? overviewData.validCount : 0;
            const expectedCount = validCount + needAttentionCount;
            const source = { needAttentionCount, expectedCount, missingCertificateCount };
            overviewData = { ...overviewData, ...source };
            setOverview(overviewData);
        }
    }, [props.overviewData, props.overviewMissingCertificatesData]);

    let activeCertsDom = null;
    const tagClasses = percentage =>
        classnames(
            { "red-dark": percentage === 0 },
            { "green-dark": percentage === 100 },
            { "yellow-medium": percentage > 0 && percentage < 100 }
        );
    if (overview && Object.keys(overview).length === 0) {
        activeCertsDom = (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h1>Loading ...</h1>
                <SLoader id="page-loader" className="large" aria-live="polite" loading />
            </div>
        );
    } else {
        const percent =
            overview.validCount && overview.expectedCount
                ? Math.floor((overview.validCount / overview.expectedCount) * 100)
                : 0;
        activeCertsDom = (
            <div className="insights-fill pad-bottom-xl pad-left-md">
                <h2 className="margin-top-sm">
                    Compliance overview
                    <span className="zoom-tip">
                        <ZoomTip
                            tipTitle="compliance"
                            url="bundle/pah1663778507797/page/eho1663778445174.html"
                        />
                    </span>
                </h2>

                <div className="margin-bottom-md">
                    <span className="margin-right-xs">You have</span>
                    <s-tag id={tagClasses(percent)} color={tagClasses(percent)}>
                        <span slot="content"> {percent}%</span>
                    </s-tag>
                    <span>of the certificates you need from customers</span>
                </div>
                <table role="presentation">
                    <tbody className="no-lines">
                        <tr>
                            <td>
                                <span className="label-weight">
                                    Expected
                                    <span className="zoom-tip">
                                        <ZoomTip
                                            tipTitle="expected"
                                            url="bundle/pah1663778507797/page/prc1702503803982.html"
                                        />
                                    </span>
                                </span>

                                <br />
                                <span>
                                    {overview.expectedCount
                                        ? overview.expectedCount.toLocaleString()
                                        : 0}{" "}
                                    certificates
                                </span>
                            </td>
                            <td>
                                <span className="label-weight">
                                    Valid
                                    <span className="zoom-tip">
                                        <ZoomTip
                                            tipTitle="valid"
                                            url="bundle/pah1663778507797/page/ikt1702503814795.html"
                                        />
                                    </span>
                                </span>
                                <br />
                                <span>
                                    {overview.validCount ? overview.validCount.toLocaleString() : 0}{" "}
                                    certificates
                                </span>
                            </td>
                            <td>
                                <span className="label-weight">
                                    Needed
                                    <span className="zoom-tip">
                                        <ZoomTip
                                            tipTitle="needed"
                                            url="bundle/pah1663778507797/page/rki1702503825935.html"
                                        />
                                    </span>
                                </span>
                                <br />
                                <span className="margin-right-lg">
                                    {overview.needAttentionCount
                                        ? overview.needAttentionCount.toLocaleString()
                                        : 0}
                                    <span> certificates </span>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="6" className="margin-top-sm">
                                <Suspense fallback={<div>Loading...</div>}>
                                    <CertificateOverviewGraph data={overview} />
                                </Suspense>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
    return <SBox className="insights-box">{activeCertsDom}</SBox>;
});

export default ActiveCertificates;
