import { SCol, SFiltersPanel, SIcon, SInputExtended, SMenu, SRow } from "@avalara/skylab-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
    clearCustomerFiltersState,
    clearFormState,
    fetchCustomersAPI,
    selectCustomerFiltersState,
    selectFormState,
    selectIsLoadingCustomerCount,
    selectLoading,
    selectNoData,
    selectPage,
    setCustomerFiltersState,
    setFormState,
    setIsBulkDeleteCustomerDialogOpen,
    setSavedSearch,
    setSavedSearchState,
} from "../../../app/customerSlice";
import { setIsExportSearchDialogOpen } from "../../../app/exportSearchSlice";
import { setIsSavedSearchDialog } from "../../../app/savedSearchesSlice";
import featureFlag from "../../../featureToggler/featureFlag";
import FeatureToggler from "../../../featureToggler/FeatureToggler";
import { isEligibleUser } from "../../../shared/sessionUtility";
import { disableLink, nextDays, previousDays } from "../../../shared/Utils";
import SelectSavedSearch from "../../sharedComponents/SelectSavedSearch";
import FilterByCertificate from "../Filters/FilterByCertificate";
import FilterByCustomer from "../Filters/FilterByCustomer";
import "./customer.css";
import CustomerColumnsManager from "./CustomerColumnManager";
import {
    setSavedSearchState as setRequestSavedSearchState,
    setFormState as setRequestFormState,
} from "../../../app/requestSlice";
import { typeAheadDelay } from "../../../shared/constants";

const scrollToRef = ref => window.scrollTo(0, ref?.current?.offsetTop);

const CustomerFilter = React.memo(() => {
    const dispatch = useDispatch();
    const customerFiltersState = useSelector(selectCustomerFiltersState);
    const navigate = useNavigate();
    const [openFilters, setOpenFilters] = useState(true);
    const { isCustomerDetails, isCertificateDetails, savedSearch } = customerFiltersState;
    const isLoading = useSelector(selectLoading);
    const formState = useSelector(selectFormState);
    const page = useSelector(selectPage);
    const paginateData = page.paginate;
    const { nameOrCode } = formState;
    const selectCustomerRegionsRef = useRef(null);
    const selectCertificateStatusRef = useRef(null);
    const selectCertificateRegionsRef = useRef(null);
    const selectexemptReasonsRef = useRef(null);
    const SelectSavedSearchRef = useRef(null);
    const scrollRef = useRef(null);
    const executeScroll = () => scrollToRef(scrollRef);
    const applyButton = document.querySelector('button[ref="apply-button"]');
    const resetButton = document.querySelector('button[ref="reset-button"]');
    const noData = useSelector(selectNoData);
    const date = new Date();
    date.setDate(date.getDate() + 1);
    const currentdate = date.toISOString().slice(0, 10);
    const createCustomerButton = "create-customer-btn";
    const isLoadingCustomerCount = useSelector(selectIsLoadingCustomerCount);
    const isEligibleUserCampaignFlag = dispatch(
        isEligibleUser(featureFlag.requestCertificates.campaign)
    );
    const isEligibleUserManageCustomerFlag = dispatch(
        isEligibleUser(featureFlag.customers.manageCustomer)
    );

    const isEligibleUserValidatedSearchPageExport = dispatch(
        isEligibleUser(featureFlag.customers.export)
    );

    const isEligibleUserCustomersSaveSearches = dispatch(
        isEligibleUser(featureFlag.customers.saveSearches)
    );

    const isUserEligibleForBulkDeleteCustomers = dispatch(
        isEligibleUser(featureFlag.customers.bulkDelete)
    );

    useEffect(() => {
        if (!isEligibleUserManageCustomerFlag) {
            disableLink(createCustomerButton);
        }
    }, [isEligibleUserManageCustomerFlag]);

    const dismissFilters = () => {
        setOpenFilters(false);
    };

    const showFilters = () => {
        setOpenFilters(!openFilters);
    };

    const applyFilters = useCallback(() => {
        if (!customerFiltersState.certificateIdError)
            dispatch(fetchCustomersAPI(paginateData.rowsPerPage, 1, false));
        executeScroll();
    }, [customerFiltersState, dispatch, paginateData.rowsPerPage]);

    const resetFilters = useCallback(async () => {
        dispatch(clearCustomerFiltersState());
        dispatch(clearFormState());
        if (SelectSavedSearchRef?.current) SelectSavedSearchRef?.current?.deselectAll();
        applyFilters();
    }, [applyFilters, dispatch]);

    const updateFilters = useCallback(
        (id, value, isfilter = true) => {
            if (customerFiltersState[id] !== value) {
                dispatch(
                    setCustomerFiltersState({
                        [id]: value,
                    })
                );
                if (isfilter) {
                    applyFilters();
                }
            }
        },
        [applyFilters, dispatch, customerFiltersState]
    );

    const handleSearchBoxSubmit = useCallback(() => {
        dispatch(fetchCustomersAPI(paginateData.rowsPerPage, 1, false));
    }, [dispatch, paginateData.rowsPerPage]);

    const clearSearchBox = () => {
        dispatch(
            setFormState({
                ...formState,
                nameOrCode: "",
            })
        );
        handleSearchBoxSubmit();
    };

    const handleInput = el => {
        dispatch(
            setFormState({
                ...formState,
                [el.detail.inputId]: el.detail.value?.trim(),
            })
        );
    };

    useEffect(() => {
        let timeout = "";
        timeout = setTimeout(() => {
            handleSearchBoxSubmit();
        }, typeAheadDelay.CustomerSearchGrid);
        return () => clearTimeout(timeout);
    }, [formState.nameOrCode]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (customerFiltersState.nameOrCode !== "") {
            dispatch(
                setFormState({
                    nameOrCode: customerFiltersState.nameOrCode,
                    nameOrCodeWhileLoading: "",
                })
            );
        }
    }, [customerFiltersState.nameOrCode, dispatch]);

    useEffect(() => {
        if (formState.nameOrCodeWhileLoading !== "" && !isLoading && !isLoadingCustomerCount) {
            handleSearchBoxSubmit();
        }
    }, [
        formState.nameOrCodeWhileLoading,
        handleSearchBoxSubmit,
        isLoading,
        isLoadingCustomerCount,
    ]);

    useEffect(() => {
        if (applyButton !== null) {
            if (isLoading || isLoadingCustomerCount) {
                applyButton.setAttribute("disabled", "disabled");
                resetButton.setAttribute("disabled", "disabled");
            } else {
                applyButton.removeAttribute("disabled");
                resetButton.removeAttribute("disabled");
            }
        }
    }, [applyButton, isLoading, resetButton, isLoadingCustomerCount]);

    const handlebtnClick = (e, value) => {
        updateFilters(e.target.id, !value, false);
    };

    const handleCheckBoxChange = useCallback(
        async e => {
            if (customerFiltersState[e.target.id] !== e.target.checked) {
                await dispatch(
                    setCustomerFiltersState({
                        [e.target.id]: e.target.checked,
                    })
                );
            }
        },
        [dispatch, customerFiltersState]
    );

    const handleChangeInput = useCallback(
        (id, value) => {
            return event => {
                dispatch(
                    setCustomerFiltersState({
                        [id]:
                            value ||
                            (event && event.target && event.target.value ? event.target.value : ""),
                    })
                );
            };
        },
        [dispatch]
    );

    const handleMultilineInputChange = useCallback(() => {
        return event => {
            if (event.target.id === "customerCodes") {
                if (
                    event.target.value?.split("\n")?.filter(e => {
                        return e != null;
                    }).length <= 1000
                ) {
                    handleChangeInput("customerCodeError", "")();
                } else {
                    // eslint-disable-next-line no-param-reassign
                    event.target.className = "error";
                    handleChangeInput(
                        "customerCodeError",
                        "Only 1000 customer codes that can be searched."
                    )();
                }
                handleChangeInput("customerCodes", event.target.value)();
            }
            if (event.target.id === "certificateIds") {
                const validInputs = /(^[0-9\n]+$|^$)/;
                if (
                    event.target.value?.split("\n")?.filter(e => {
                        return e != null;
                    }).length <= 100
                ) {
                    if (event.target.value?.match(validInputs)) {
                        event.target.className = ""; // eslint-disable-line
                        handleChangeInput("certificateIdError", "")();
                    } else {
                        event.target.className = "error"; // eslint-disable-line
                        handleChangeInput(
                            "certificateIdError",
                            "Certificate Id should be numeric."
                        )();
                    }
                } else {
                    event.target.className = "error"; // eslint-disable-line no-param-reassign
                    handleChangeInput(
                        "certificateIdError",
                        "Only 100 certificicate Ids that can be searched."
                    )();
                }
                handleChangeInput("certificateIds", event.target.value)();
            }
        };
    }, [handleChangeInput]);

    const handleSavedSearchAdd = optObj => {
        const searchCriteriaObj = JSON.parse(
            optObj.value
                .replace("Last30days", previousDays(30))
                .replace("Next30days", nextDays(30))
                .replace("today", currentdate)
        );
        dispatch(clearCustomerFiltersState());
        dispatch(setSavedSearchState(searchCriteriaObj));
        dispatch(setSavedSearch(optObj.label));

        // setFormState will internally trigger a call to fetchCustomersAPI. So we shouldn't be making duplicate calls here.
        if (searchCriteriaObj.nameOrCode) {
            dispatch(
                setFormState({
                    nameOrCode: searchCriteriaObj.nameOrCode,
                    nameOrCodeWhileLoading: "",
                })
            );
        } else {
            dispatch(fetchCustomersAPI(paginateData.rowsPerPage, 1, false));
        }
    };

    const handleSavedSearchRemove = () => {
        dispatch(clearCustomerFiltersState());
        dispatch(setSavedSearch(""));
        dispatch(clearFormState());
        dispatch(fetchCustomersAPI(paginateData.rowsPerPage, 1, false));
    };

    const OpenSaveSearchDialog = () => {
        dispatch(setIsSavedSearchDialog(true));
    };

    const OpenExportSearchDialog = () => {
        dispatch(setIsExportSearchDialogOpen(true));
    };

    const OpenDeleteCustomerDialog = () => {
        dispatch(setIsBulkDeleteCustomerDialogOpen(true));
    };
    // Set RequestsFilterState and navigate
    const navigateToRequests = () => {
        dispatch(
            setRequestSavedSearchState({
                ...customerFiltersState,
            })
        );
        dispatch(setRequestFormState({ ...formState }));
        navigate("/requests/create");
    };

    const navigateToImport = () => {
        navigate("/import-customers");
    };

    return (
        <React.Fragment>
            <FeatureToggler category="reports" id="viewReports">
                <SRow className="pad-top-sm">
                    <SCol span="12" className="text-right">
                        <Link
                            className="font-semibold"
                            to="/reports/saved-searches"
                            aria-label="Reports">
                            Reports
                        </Link>
                    </SCol>
                </SRow>
            </FeatureToggler>
            <SRow className="align-items-end">
                <SCol span="xl-8 6">
                    <SRow className="margin-left-none align-items-end">
                        <button type="button" className="secondary" onClick={showFilters}>
                            <SIcon name="filter" className="margin-right-xs" aria-hidden="true" />
                            Filters
                        </button>

                        <SCol span="md-6 xl-6 5" className="pad-bottom-none">
                            <label htmlFor="nameOrCode">
                                Search by customer name, customer code or address
                            </label>
                            <SInputExtended
                                inputId="nameOrCode"
                                aria-label="customer name, customer code or address"
                                placeholder=" "
                                type="search"
                                onS-input={e => handleInput(e)}
                                onS-search={e => handleSearchBoxSubmit(e)}
                                onS-clear={() => clearSearchBox()}
                                value={nameOrCode}
                                className={
                                    isLoading || isLoadingCustomerCount
                                        ? "disable-sinput-extended"
                                        : "none"
                                }
                            />
                        </SCol>
                        <CustomerColumnsManager />
                    </SRow>
                </SCol>

                <SCol span="4" className="flex justify-content-end">
                    <span className="flex justify-content-start pad-right-md">
                        <SMenu>
                            <button
                                slot="menu-trigger"
                                className="secondary icon"
                                aria-controls="menu1-id">
                                <SIcon name="more" aria-label="more" />
                            </button>
                            <ul slot="menu-items" id="menu1-id">
                                <li>
                                    <button
                                        disabled={
                                            !isEligibleUserManageCustomerFlag ||
                                            isLoadingCustomerCount
                                        }
                                        className="link"
                                        title="Import"
                                        onClick={navigateToImport}>
                                        Import
                                    </button>
                                </li>
                                <li>
                                    <button
                                        disabled={
                                            isLoading ||
                                            isLoadingCustomerCount ||
                                            !isEligibleUserValidatedSearchPageExport ||
                                            paginateData.totalRecords <= 0 ||
                                            paginateData.indeterminate
                                        }
                                        className="link"
                                        onClick={OpenExportSearchDialog}>
                                        Export
                                    </button>
                                </li>
                                <FeatureToggler category="customers" id="saveSearches">
                                    <li>
                                        <button
                                            disabled={
                                                isLoading ||
                                                isLoadingCustomerCount ||
                                                !isEligibleUserCustomersSaveSearches
                                            }
                                            className="link"
                                            onClick={OpenSaveSearchDialog}>
                                            Save search as report
                                        </button>
                                    </li>
                                </FeatureToggler>
                                <FeatureToggler category="customers" id="manageCustomer">
                                    <li>
                                        <button
                                            disabled={
                                                isLoading ||
                                                isLoadingCustomerCount ||
                                                !isUserEligibleForBulkDeleteCustomers ||
                                                paginateData.totalRecords <= 0 ||
                                                paginateData.indeterminate
                                            }
                                            className="link"
                                            onClick={OpenDeleteCustomerDialog}>
                                            Delete the customer records
                                        </button>
                                    </li>
                                </FeatureToggler>
                            </ul>
                        </SMenu>
                    </span>
                    <FeatureToggler category="requestCertificates" id="campaign">
                        <button
                            disabled={
                                isLoading ||
                                noData !== "" ||
                                isLoadingCustomerCount ||
                                !isEligibleUserCampaignFlag
                            }
                            className="secondary inline margin-right-md"
                            title="Request certificates"
                            onClick={navigateToRequests}>
                            Request certificates
                        </button>
                    </FeatureToggler>
                    <span className="flex justify-content-end">
                        <Link to="/add-customer" id={createCustomerButton}>
                            <button
                                type="button"
                                className="primary inline"
                                aria-disabled={
                                    !isEligibleUserManageCustomerFlag || isLoadingCustomerCount
                                }
                                disabled={
                                    !isEligibleUserManageCustomerFlag || isLoadingCustomerCount
                                }>
                                <SIcon name="plus" className="margin-right-xs" aria-hidden="true" />
                                Add a customer
                            </button>
                        </Link>
                    </span>
                </SCol>
            </SRow>
            <SFiltersPanel
                open={openFilters}
                id="customer-filters-panel"
                onS-dismiss={dismissFilters}
                onS-reset={resetFilters}
                onS-apply={applyFilters}
                className="margin-top-xl margin-right-lg">
                <div slot="filters-panel-content">
                    <FeatureToggler category="reports" id="viewReports">
                        <SRow>
                            <SCol span="12" className="pad-bottom-sm">
                                <SelectSavedSearch
                                    ref={SelectSavedSearchRef}
                                    loading={isLoading || isLoadingCustomerCount}
                                    onAdd={e => handleSavedSearchAdd(e)}
                                    onRemove={e => handleSavedSearchRemove(e)}
                                    value={savedSearch}
                                    label="Reports"
                                    disabled={isLoading || isLoadingCustomerCount}
                                    searchType="Customer"
                                />
                            </SCol>
                        </SRow>
                    </FeatureToggler>
                    <SRow>
                        <SCol span="12" className="pad-right-md pad-left-md">
                            <div>
                                <button
                                    id="isCertificateDetails"
                                    className="ghost-blue icon-trailing pad-all-none"
                                    onClick={e => handlebtnClick(e, isCertificateDetails)}>
                                    Certificate details
                                    <SIcon
                                        id="isCertificateDetails"
                                        name={isCertificateDetails ? "chevron-up" : "chevron-down"}
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                            <FilterByCertificate
                                isLoading={isLoading || isLoadingCustomerCount}
                                updateFilters={updateFilters}
                                handleCheckBoxChange={handleCheckBoxChange}
                                handleMultilineInputChange={handleMultilineInputChange}
                                applyFilters={applyFilters}
                                filterState={customerFiltersState}
                                setFilterState={setCustomerFiltersState}
                                ref={{
                                    selectexemptReasonsRef,
                                    selectCertificateStatusRef,
                                    selectCertificateRegionsRef,
                                }}
                                usedBy="CustomerSearch"
                            />
                            <div>
                                <button
                                    id="isCustomerDetails"
                                    className="ghost-blue icon-trailing pad-right-none pad-left-none"
                                    onClick={e => handlebtnClick(e, isCustomerDetails)}>
                                    Customer details
                                    <SIcon
                                        id="isCustomerDetails"
                                        name={isCustomerDetails ? "chevron-up" : "chevron-down"}
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                            <FilterByCustomer
                                isLoading={isLoading || isLoadingCustomerCount}
                                updateFilters={updateFilters}
                                handleCheckBoxChange={handleCheckBoxChange}
                                handleMultilineInputChange={handleMultilineInputChange}
                                filterState={customerFiltersState}
                                setFilterState={setCustomerFiltersState}
                                ref={selectCustomerRegionsRef}
                                usedBy="CustomerSearch"
                            />
                        </SCol>
                    </SRow>
                </div>
            </SFiltersPanel>
        </React.Fragment>
    );
});

export default CustomerFilter;
