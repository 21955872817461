import React from "react";
import _ from "lodash";
import axios from "axios";
import { SToasts } from "@avalara/skylab-react";
import { logger } from "../../shared/Logger";
import axiosObject from "../../axios";
import ErrorPage from "./ErrorPage";
import store from "../../app/store";
import getConfig from "../../config";
import { errorResponse } from "../../shared/responseUtils";
import { getMessage } from "./errorBoundaryUtils";
import { UnhandledError, ACTIVITY_STORAGE_KEY } from "../../shared/constants";

const { appEnv, hostnames } = getConfig();
const { ecmApiHost } = hostnames;
// Constants
const INACTIVITY_TIMEOUT = 30 * 60 * 1000; // 30 minutes
const THROTTLE_WAIT = 1000; // 1 second
const NETWORK_ERROR_CODES = ["ECONNABORTED", "ERR_NETWORK"];
const ACTIVITY_EVENTS = ["mousedown", "keydown", "touchstart", "scroll"];

window.addEventListener("error", async event => {
    const { message, filename, lineno, colno } = event;
    const params = { message, filename, lineno, colno };
    await logger(2, 4, params);
});

class ErrorBoundary extends React.Component {
    static isNetworkError(error) {
        return (
            (error?.code && NETWORK_ERROR_CODES.some(code => error.code.includes(code))) ||
            error.message === "Network Error"
        );
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    static resetActivityTimer() {
        localStorage.setItem(ACTIVITY_STORAGE_KEY, Date.now().toString());
    }

    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            lastActivityTime: parseInt(localStorage.getItem(ACTIVITY_STORAGE_KEY) || "0", 10),
        };
        this.abortController = new AbortController();

        this.updateActivity = _.throttle(this.handleActivity, THROTTLE_WAIT, {
            leading: true,
            trailing: true,
        });
        this.requestInterceptor = null;
        this.responseInterceptor = null;

        this.errorHandler = async error => {
            let params = {};
            if (error?.request) {
                params = {
                    method: error?.config?.method,
                    url: error?.config?.url,
                    status: error?.request?.status,
                    statusText: error?.request?.statusText,
                };
            } else if (error?.response) {
                params = {
                    method: error?.config?.method,
                    url: error?.config?.url,
                    status: error?.response?.status,
                    statusText: error?.response?.statusText,
                };
            }
            await logger(1, 4, params);
        };
    }

    componentDidMount() {
        this.setupUserActivityTracking();
        this.setupAxiosInterceptors();
    }

    componentDidCatch(error) {
        this.setState({ hasError: true });
    }

    componentWillUnmount() {
        this.cleanupUserActivityTracking();

        // Remove handlers, so Garbage Collector will get rid of if WrappedComponent will be removed
        this.cleanupAxiosInterceptors();
        this.abortController.abort();
    }

    setupUserActivityTracking = () => {
        const { signal } = this.abortController;

        ACTIVITY_EVENTS.forEach(event => {
            document.addEventListener(event, this.updateActivity, { signal });
        });

        window.addEventListener("storage", this.handleStorageChange, { signal });

        if (!this.state.lastActivityTime) {
            this.handleActivity();
        }
    };

    cleanupUserActivityTracking = () => {
        this.updateActivity.cancel();
    };

    handleStorageChange = event => {
        if (event.key === ACTIVITY_STORAGE_KEY) {
            const newActivityTime = parseInt(event.newValue || "0", 10);
            this.setState({ lastActivityTime: newActivityTime });
        }
    };

    isUserActive = () => {
        return Date.now() - this.state.lastActivityTime <= INACTIVITY_TIMEOUT;
    };

    handleActivity = () => {
        const currentTime = Date.now();
        localStorage.setItem(ACTIVITY_STORAGE_KEY, currentTime.toString());
        this.setState({ lastActivityTime: currentTime });
    };

    // Request Interceptor Setup
    setupRequestInterceptor = () => {
        // Set axios interceptors
        const api = `/api/`;
        this.requestInterceptor = axiosObject.interceptors.request.use(
            req => {
                this.setState({ hasError: false });
                const { session } = store.getState();
                if (req.url.includes("session")) {
                    return req;
                }

                const urlSplit = req.url.split(api);
                const version = urlSplit[1].split("/")[0];
                const methodName = urlSplit[1].replace(version, "");
                req.url = `${urlSplit[0]}${api}${version}/companies/${session.activeCompany.id}${methodName}`;

                return req;
            },
            async error => {
                this.setState({ hasError: true });
                await this.errorHandler(error);
                return Promise.reject(error);
            }
        );
    };

    // Response Interceptor Setup
    setupResponseInterceptor = () => {
        this.responseInterceptor = axiosObject.interceptors.response.use(
            async res => {
                return res;
            },
            async error => {
                const traceId = error?.response?.headers["x-trace-id"] ?? null;
                // Stops poller if there is an API error
                if (
                    error?.config?.method === "get" &&
                    error?.response?.request?.status === 500 &&
                    error?.response?.request?.responseURL.includes("auto-validation-result")
                ) {
                    window.location.reload();
                }

                const parseError = errorResponse(error);

                if (error.response && error.response.status && error.response.status === 401) {
                    await axios
                        .get(`//${ecmApiHost}/api/v3/session`, {
                            withCredentials: true,
                        })
                        .then(async sessionResponse => {
                            if (!(sessionResponse.data && sessionResponse.data.isAuthenticated)) {
                                window.location = `//${ecmApiHost}/api/v3/auth/login?redirectUrl=${window.location.href}`;
                            } else {
                                this.setState({ hasError: true, status: error.response.status });
                                await this.errorHandler(error);
                            }
                        })
                        .catch(async () => {
                            this.setState({ hasError: true, status: error.response.status });
                        });
                } else if (parseError && parseError?.code) {
                    const responseObj = getMessage(error);
                    if (responseObj?.responseMessage) {
                        if (
                            appEnv !== "prd" &&
                            appEnv !== "sbx" &&
                            responseObj?.responseMessage?.messageType === UnhandledError
                        ) {
                            this.setState({
                                hasError: true,
                                status: parseError?.status,
                                traceId,
                            });
                            await this.errorHandler(error);
                        }
                        return responseObj?.responseMessage;
                    }
                    if (responseObj) {
                        return responseObj;
                    }
                    if (parseError?.status === 500) {
                        this.setState({
                            hasError: true,
                            status: parseError?.status,
                            traceId,
                        });
                        await this.errorHandler(error);
                    }
                    return error;
                } else if (ErrorBoundary.isNetworkError(error) && !this.isUserActive()) {
                    this.redirectToLogout();
                } else {
                    this.setState({ hasError: true, status: error?.response?.status, traceId });
                    await this.errorHandler(error);
                }
                return Promise.reject(error);
            }
        );
    };

    // Setup and Cleanup AxiosInterceptors

    setupAxiosInterceptors = () => {
        this.setupRequestInterceptor();
        this.setupResponseInterceptor();
    };

    cleanupAxiosInterceptors = () => {
        if (this.requestInterceptor !== null) {
            axiosObject.interceptors.request.eject(this.requestInterceptor);
        }
        if (this.responseInterceptor !== null) {
            axiosObject.interceptors.response.eject(this.responseInterceptor);
        }
    };

    redirectToLogout = () => {
        try {
            // Only remove the activity storage key
            localStorage.removeItem(ACTIVITY_STORAGE_KEY);

            // Construct base URL
            const logoutUrl = new URL(
                "/api/v3/auth/logout",
                `${appEnv === "local" ? "http" : "https"}://${ecmApiHost}`
            );

            this.setState({ hasError: false });
            window.location.href = logoutUrl.toString();
        } catch (error) {
            // Even if redirect fails, try to remove activity storage key
            try {
                localStorage.removeItem(ACTIVITY_STORAGE_KEY);
            } catch (storageError) {
                // eslint-disable-next-line
                console.error("Failed to remove activity storage key:", storageError);
            }
            this.errorHandler(error);
            window.location.reload();
        }
    };

    render() {
        <SToasts role="status" />;
        if (this?.state?.hasError) {
            return <ErrorPage type={this?.state?.status} traceId={this?.state?.traceId} />;
        }
        return this?.props?.children;
    }
}

export default ErrorBoundary;
