import { createSlice } from "@reduxjs/toolkit";
import { Buffer } from "buffer";
import axios from "../axios";
import axiosInstanceWithoutInterceptor from "../axiosWithoutInterceptor";
import { buildApiV3Url } from "../shared/Utils";

const initialState = {
    data: [],
    isLoading: false,
    isFormLoading: false,
    activeLaw: 0,
    activeEntities: 0,
    activeForm: 0,
    formData: { fileType: "", data: "", fileName: "" },
    certificateForms: [],
};

export const contentSlice = createSlice({
    name: "content",
    initialState,

    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setFormLoading: (state, action) => {
            state.isFormLoading = action.payload;
        },
        setActiveLaw: (state, action) => {
            state.activeLaw = action.payload;
        },
        setActiveEntities: (state, action) => {
            state.activeEntities = action.payload;
        },
        setActiveForm: (state, action) => {
            state.activeForm = action.payload;
        },
        setFormData: (state, action) => {
            state.formData = action.payload;
        },
        clearFormData: state => {
            state.formData = initialState.formData;
        },
        setCertificateForms: (state, action) => {
            state.certificateForms = action.payload;
        },
    },
});
export const {
    setData,
    setLoading,
    setFormLoading,
    setActiveLaw,
    setActiveEntities,
    setActiveForm,
    setFormData,
    clearFormData,
    setCertificateForms,
} = contentSlice.actions;

export const selectData = state => {
    return state.content.data;
};
export const selectIsLoading = state => {
    return state.content.isLoading;
};
export const selectIsFormLoading = state => {
    return state.content.isFormLoading;
};
export const selectActiveLaw = state => {
    return state.content.activeLaw;
};
export const selectActiveEntities = state => {
    return state.content.activeEntities;
};
export const selectActiveForm = state => {
    return state.content.activeForm;
};
export const selectFormData = state => {
    return state.content.formData;
};
export const selecCertificateForms = state => {
    return state.content.certificateForms;
};

export const fetchTaxAnswers = stateInitials => async (dispatch, getState) => {
    const { isLoading } = getState().content;
    if (isLoading) {
        return;
    }
    dispatch(setLoading(true));

    const response = await axiosInstanceWithoutInterceptor.get(
        buildApiV3Url(`tax-content/tax-answers?stateCode=${stateInitials}`),
        { withCredentials: true }
    );
    if (response?.data?.answers) {
        dispatch(
            setData({
                data: response.data.answers,
            })
        );
    } else {
        dispatch(setData([]));
    }

    dispatch(setLoading(false));
};

export const fetchTaxLaws = stateInitials => async (dispatch, getState) => {
    const { isLoading } = getState().content;
    if (isLoading) {
        return;
    }
    dispatch(setLoading(true));

    const response = await axiosInstanceWithoutInterceptor.get(
        buildApiV3Url(`tax-content/tax-laws?stateCode=${stateInitials}`),
        { withCredentials: true }
    );
    if (response?.data?.laws) {
        dispatch(
            setData({
                data: response.data.laws,
            })
        );
    } else dispatch(setData([]));
    if (response?.data?.laws?.[0]?.id) dispatch(setActiveLaw(response.data.laws[0].id));
    else dispatch(setActiveLaw(0));
    dispatch(setLoading(false));
};

export const fetchExemptEntities = stateInitials => async (dispatch, getState) => {
    const { isLoading } = getState().content;
    if (isLoading) {
        return;
    }
    dispatch(setLoading(true));

    const response = await axiosInstanceWithoutInterceptor.get(
        buildApiV3Url(`tax-content/exempt-entities?stateCode=${stateInitials}`),
        { withCredentials: true }
    );
    if (response?.data?.entities) {
        const temp = response.data.entities;
        dispatch(
            setData({
                data: temp[Object.keys(temp)[0]],
            })
        );
        dispatch(setActiveEntities(temp[Object.keys(temp)[0]][0]?.id));
    } else {
        dispatch(setData([]));
        dispatch(setActiveEntities(0));
    }
    dispatch(setLoading(false));
};

export const fetchCertificateForms =
    (stateName, filterRequestForms = false, useRegionFilter = true) =>
    async (dispatch, getState) => {
        const { isLoading } = getState().content;
        if (isLoading) {
            return;
        }
        dispatch(setLoading(true));
        const response = await axios.get(
            buildApiV3Url(
                `tax-content/certificate-forms?exposureZoneName=${stateName}&filterRequestForms=${filterRequestForms}&useRegionFilter=${useRegionFilter}`
            ),
            { withCredentials: true }
        );
        if (response?.data?.[0]?.id) {
            dispatch(
                setCertificateForms({
                    data: response.data,
                })
            );
            dispatch(setActiveForm(response.data[0].id));
        } else {
            dispatch(setCertificateForms([]));
            dispatch(clearFormData());
            dispatch(setActiveForm(0));
        }
        dispatch(setLoading(false));
    };

export const getCertificateFormFile = (UUID, fileName) => async (dispatch, getState) => {
    const { isFormLoading } = getState().content;
    if (isFormLoading) {
        return;
    }
    dispatch(setFormLoading(true));
    const response = await axios.get(buildApiV3Url(`tax-content/certificate-form-file`), {
        params: {
            uuid: UUID,
            filename: fileName,
        },
        withCredentials: true,
        responseType: "arraybuffer",
    });
    if (response.headers["content-type"].includes("application/json")) {
        dispatch(setFormData(initialState.formData));
    } else if (response && response.data) {
        const buffer = Buffer.from(response.data, "binary").toString("base64");
        dispatch(
            setFormData({
                fileType: `data:${response.headers["content-type"]};base64`,
                data: `data:${response.headers["content-type"]};base64,${buffer}`,
                fileName,
            })
        );
    }
    dispatch(setFormLoading(false));
};

export default contentSlice.reducer;
