import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SIcon, SBox, STag, SLoader } from "@avalara/skylab-react";
import { v4 as uuidv4 } from "uuid";
import {
    getCustomerCustomFields,
    selectCustomerCustomFields,
    selectIsLoadingCustomerCustomFields,
} from "../../app/customerSlice";
import "./customerInfoPanel.css";

const CustomerInfoPanel = React.memo(props => {
    const dispatch = useDispatch();
    const ref = useRef(null);
    const { focusedCustomer } = props;
    const customFields = useSelector(selectCustomerCustomFields, shallowEqual);
    const [customFieldsCollapsed, setCustomFieldsCollapsed] = useState(true);
    const isLoadingCustomerCustomFields = useSelector(selectIsLoadingCustomerCustomFields);

    useEffect(() => {
        const handleClickOutside = event => {
            if (ref.current && !ref.current.contains(event.target)) {
                props.setFocusedCustomer(null);
            }
        };
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, []);

    const viewCustomer = () => {
        const entityType = focusedCustomer?.isVendor ? "vendor" : "customer";
        window.open(`${window.location.origin}/${entityType}/${focusedCustomer.id}`, "_blank");
    };
    const onClose = () => {
        props.setFocusedCustomer(null);
    };
    const toggleChevron = async () => {
        setCustomFieldsCollapsed(!customFieldsCollapsed);
        if (customFieldsCollapsed) await dispatch(getCustomerCustomFields(focusedCustomer.id));
    };

    const getCustomerAddress = () => {
        const parts = [
            focusedCustomer.addressLine1,
            focusedCustomer.addressLine2,
            focusedCustomer.city,
            focusedCustomer.state?.initials,
            focusedCustomer.zip,
            focusedCustomer.country?.initials,
        ].filter(Boolean);

        const addressLines = parts.join(", ");

        return addressLines.length > 0 ? (
            addressLines
        ) : (
            <STag color="yellow" id={focusedCustomer.id}>
                <span slot="content">Missing mailing address</span>
            </STag>
        );
    };

    const getMissingInfo = missingInfoText => {
        return (
            <STag color="yellow-medium" id={uuidv4()}>
                <span slot="content">{missingInfoText}</span>
            </STag>
        );
    };

    return (
        <div ref={ref}>
            <SBox className="customerBoxStyle">
                <div className="pad-bottom-sm ">
                    <s-table-container>
                        <table className="table-style" role="presentation">
                            <thead className="theadStyle">
                                <tr>
                                    <th>Customer code</th>
                                    <th>Alternate ID</th>
                                    <th>Taxpayer ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {focusedCustomer.customerNumber
                                            ? focusedCustomer.customerNumber
                                            : getMissingInfo("Missing customer code")}
                                    </td>
                                    <td>
                                        {focusedCustomer.alternateId
                                            ? focusedCustomer.alternateId
                                            : getMissingInfo("Missing alternate ID")}
                                    </td>
                                    <td>
                                        {focusedCustomer.feinNumber
                                            ? focusedCustomer.feinNumber
                                            : getMissingInfo("Missing taxpayer ID")}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </s-table-container>
                </div>
                <div className="pad-bottom-sm">
                    <s-table-container>
                        <table className="table-style" role="presentation">
                            <thead className="theadStyle">
                                <tr>
                                    <th>Mailing address</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{getCustomerAddress()}</td>
                                </tr>
                            </tbody>
                        </table>
                    </s-table-container>
                </div>
                <div className="pad-bottom-sm ">
                    <s-table-container>
                        <table className="table-style" role="presentation">
                            <thead className="theadStyle">
                                <tr>
                                    <th>Primary contact</th>
                                    <th>Phone</th>
                                    <th>Email</th>
                                    <th>Fax</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {focusedCustomer.contactName
                                            ? focusedCustomer.contactName
                                            : getMissingInfo("Missing primary contact")}
                                    </td>
                                    <td>
                                        {focusedCustomer.phoneNumber
                                            ? focusedCustomer.phoneNumber
                                            : getMissingInfo("Missing phone")}
                                    </td>
                                    <td>
                                        {focusedCustomer.emailAddress
                                            ? focusedCustomer.emailAddress
                                            : getMissingInfo("Missing email")}
                                    </td>
                                    <td>
                                        {focusedCustomer.faxNumber
                                            ? focusedCustomer.faxNumber
                                            : getMissingInfo("Missing fax")}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </s-table-container>
                </div>

                <button
                    hidden={focusedCustomer?.isVendor}
                    className="link"
                    onClick={() => toggleChevron()}>
                    Additional customer info
                    <SIcon
                        name={customFieldsCollapsed ? "chevron-down" : "chevron-up"}
                        className="pad-left-xs"
                        aria-hidden="true"
                    />
                </button>

                {!customFields.filter(x => x.value).length > 0 &&
                !customFieldsCollapsed &&
                !isLoadingCustomerCustomFields ? (
                    <p>This customer doesn&apos;t have additional info.</p>
                ) : null}
                {customFields.filter(x => x.value).length > 0 &&
                !customFieldsCollapsed &&
                !isLoadingCustomerCustomFields ? (
                    <div className="pad-bottom-sm pad-top-sm">
                        <s-table-container>
                            <table className="table-style" role="presentation">
                                <thead className="theadStyle">
                                    <tr>
                                        <th>Custom field</th>
                                        <th>Field value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {customFields.map(customField =>
                                        customField.value ? (
                                            <tr key={uuidv4()}>
                                                <td>
                                                    {customField.value
                                                        ? customField.fieldName
                                                        : null}
                                                </td>
                                                <td>
                                                    {customField.value ? customField.value : null}
                                                </td>
                                            </tr>
                                        ) : null
                                    )}
                                </tbody>
                            </table>
                        </s-table-container>
                    </div>
                ) : null}
                {isLoadingCustomerCustomFields && (
                    <SLoader className="small" aria-live="polite" loading />
                )}
                <hr className="hr-width" />
                <span className="left pad-top-xs pad-bottom-xs">
                    <button className="primary small" onClick={viewCustomer}>
                        View customer
                    </button>
                    <button className="tertiary small" onClick={onClose}>
                        Close
                    </button>
                </span>
            </SBox>
        </div>
    );
});

export default CustomerInfoPanel;
