import React, { useEffect, useState } from "react";
import { SCol, SDialog, SIcon, SLoader, SRow, SSelect } from "@avalara/skylab-react";
import classnames from "classnames";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import expirationTimeFrames from "./ExpirationTimeFrames";
import { errorTag, getTimeFrame, noErrorTag } from "../../../../shared/Utils";
import {
    fetchCertificateForms,
    selectIsLoading,
    selecCertificateForms,
    getCertificateFormFile,
    selectFormData,
    selectIsFormLoading,
} from "../../../../app/contentSlice";
import SaveResetConfirmationDialog from "./SaveResetConfirmationDialog";
import CancelConfirmationDialog from "./CancelConfirmationDialog";
import { isEligibleUser } from "../../../../shared/sessionUtility";
import {
    setMatrixSetting,
    setRequestingCertificateFormsDialogOpen,
    setResetMatrixSetting,
    getMatrixFile,
    selectMatrixFile,
    selectIsSavingMatrixFile,
    setMatrixFile,
    selectIsLoadingMatrixFile,
    setLoadingMatrixFile,
    checkIfSystemCollection,
    selectDefaultExist,
    putDefaultMatrixSetting,
} from "../../../../app/settingsSlice";
import FileRender from "../../../sharedComponents/FileRenderer";
import "../../settingsStyle.css";
import featureFlag from "../../../../featureToggler/featureFlag";

function RequestingCertificateFormsDialog(props) {
    const saveClassName = classnames({ primary: true, small: true, loading: false });
    const [acceptReason, setAcceptReason] = useState(false);
    const [showMonthDayText, setMonthDayText] = useState(false);
    const dispatch = useDispatch();
    const loading = useSelector(selectIsLoading);
    const isDefault = useSelector(selectDefaultExist);
    const certificateFormsData = useSelector(selecCertificateForms, shallowEqual);
    const formData = useSelector(selectFormData, shallowEqual);
    const [isSaveConfirmationDialogOpen, setIsSaveConfirmationDialogOpen] = useState(false);
    const [isCancelConfirmationDialogOpen, setIsCancelConfirmationDialogOpen] = useState(false);
    const [certFormName, setCertFormName] = useState("");
    const [timeLength, setTimeLength] = useState("");
    const [expTimeFrame, setExpTimeFrame] = useState("");
    const [question, setQuestion] = useState("");
    const [duration, setDuration] = useState("");
    const [durationError, setDurationError] = useState("");
    const [isDisplayMonth, setIsDisplayMonth] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const matrixFile = useSelector(selectMatrixFile);
    const [defaultSource, setDefaultSource] = useState(true);
    const auiUploaderRef = React.createRef();
    const isSavingMatrixFile = useSelector(selectIsSavingMatrixFile);
    const isLoadingMatrixFile = useSelector(selectIsLoadingMatrixFile);
    const formLoading = useSelector(selectIsFormLoading);
    const previewClassName = classnames({ small: true, loading: formLoading });
    const [certFormObj, setCertFormObj] = useState(null);
    const [byPassConfirm, setByPassConfirm] = useState(false);
    const ecmRoleAndSubscriptionCheck = dispatch(
        isEligibleUser(featureFlag.settings.requestingCertExemptionMatrixUpdate)
    );

    const resetAuiRef = () => {
        if (auiUploaderRef.current) {
            auiUploaderRef.current.reset();
        }
        const uploader = document.getElementById("aui-uploader");
        if (uploader) {
            uploader.reset();
        }
    };
    useEffect(() => {
        if (!Object.is(certFormName, null)) {
            const formObj = certificateFormsData?.data?.find(x => x.tag === certFormName);
            setCertFormObj(formObj);
        }
    }, [JSON.stringify(certificateFormsData)]);

    useEffect(() => {
        dispatch(fetchCertificateForms(props?.certificateForm?.regionName, false, false));
    }, [dispatch, props?.certificateForm?.regionName]);

    useEffect(() => {
        if (props?.certificateForm?.staticFile) {
            dispatch(getMatrixFile(props.certificateForm.id));
            setDefaultSource(false);
        }
    }, [dispatch, props?.certificateForm?.id, props?.certificateForm?.staticFile]);

    useEffect(() => {
        dispatch(
            checkIfSystemCollection(
                props.certificateForm.exemptReasonTag,
                props.certificateForm.regionTag
            )
        );
    }, [props?.certificateForm?.regionTag, props?.certificateForm?.exemptReasonTag]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (formData?.data) {
            localStorage.setItem("certificateForm", formData.data);
        }
        return () => {
            localStorage.setItem("certificateForm", "");
        };
    }, [JSON.stringify(formData)]);
    useEffect(() => {
        if (
            props?.certificateForm?.templateTag &&
            props?.certificateForm?.templateTag !== "NOTEMPLATE"
        )
            setCertFormName(props?.certificateForm?.templateTag);
        if (props?.certificateForm?.timeLength) {
            const propsTimeLength = props?.certificateForm?.timeLength;
            const { timeFrame, timeDuration, isMonth } = getTimeFrame(propsTimeLength);
            setTimeLength(timeFrame);
            setExpTimeFrame(timeFrame);
            setDuration(timeDuration);
            setIsDisplayMonth(isMonth);
            setMonthDayText(propsTimeLength?.match(/months|days/) != null);
        }
        setQuestion(props?.certificateForm?.question);
        dispatch(setMatrixFile(""));
        dispatch(setLoadingMatrixFile(false));
    }, [
        dispatch,
        props?.certificateForm?.question,
        props?.certificateForm?.templateTag,
        props?.certificateForm?.timeLength,
    ]);

    const handleCheckBoxChange = e => {
        setAcceptReason(e.target.checked);
    };
    const timeframes = expirationTimeFrames.map(element => {
        if (timeLength && element.id === timeLength) {
            return { label: element.label, value: element.id, selected: true };
        }
        return { label: element.label, value: element.id };
    });

    const getCertFormsOptionList = () => {
        let tempList = certificateFormsData?.data?.map(element => {
            return {
                label: element.name,
                value: element.tag,
                selected: element.tag === certFormName,
            };
        });

        if (!tempList) {
            tempList = [];
        }
        tempList?.push({
            label: "Manual Submission Required",
            value: "",
            selected: certFormName === "" || certFormName === "NONE",
        });

        return tempList;
    };

    const renderForm = async () => {
        await dispatch(getCertificateFormFile(certFormObj?.s3_UUID, certFormObj?.fileName));
        window.open(`${window.location.origin}/certificate-forms`, "_blank", "noopener,noreferrer");
    };

    const saveMatrixSetting = async byPassConfirmation => {
        if (showMonthDayText && (!duration || duration <= 0)) {
            setDurationError("Enter a number.");
            return false;
        }
        setIsUpdate(true);
        const staticFile = null;

        const matrixSetting = {
            id: props?.certificateForm?.id,
            templateTag: certFormName,
            timeLength: showMonthDayText ? `${duration} ${timeLength}` : timeLength,
            question,
            outgoing: false,
            staticFile,
        };
        await dispatch(setMatrixSetting(matrixSetting));
        if (byPassConfirmation) {
            dispatch(putDefaultMatrixSetting(matrixSetting.id));
            dispatch(setRequestingCertificateFormsDialogOpen(false));
        } else {
            setIsSaveConfirmationDialogOpen(true);
            document.getElementById("saveResetConfirmationDialog")?.showModal();
        }
        return true;
    };
    const handleSaveEvent = () => {
        saveMatrixSetting(byPassConfirm);
        resetAuiRef();
    };

    const resetMatrixSetting = () => {
        setIsUpdate(false);
        const resetMatrixData = {
            id: props?.certificateForm?.id,
            regionName: props?.certificateForm?.regionName,
            reasonName: props?.certificateForm?.exemptReasonName,
        };
        dispatch(setResetMatrixSetting(resetMatrixData));
        setIsSaveConfirmationDialogOpen(true);
        document.getElementById("saveResetConfirmationDialog")?.showModal();
    };

    const cancelDialog = () => {
        if (
            props.certificateForm.question !== question ||
            (props.certificateForm.templateTag &&
                props.certificateForm.templateTag !== certFormName) ||
            expTimeFrame !== timeLength
        ) {
            setIsCancelConfirmationDialogOpen(true);
        } else {
            setIsCancelConfirmationDialogOpen(false);
            dispatch(setRequestingCertificateFormsDialogOpen(false));
        }
    };

    const onSelectChange = (type, e) => {
        const value = e?.detail?.item?.value;
        if (type === "certForms") {
            setCertFormName(value);
            const formObj = certificateFormsData?.data?.find(x => x.tag === value);
            setCertFormObj(formObj);
        } else {
            setTimeLength(value);
        }
        setMonthDayText(value?.match(/months|days/) != null);
        const isMonthPresent = value?.match(/months/) != null;
        setIsDisplayMonth(isMonthPresent);
        setDuration(isMonthPresent ? 6 : 14);
        setDurationError("");
    };

    const handleChangeInput = setter => {
        return event => {
            if (event.target.value && event.target.className.match(/error/) != null) {
                if (event.target.name === "duration") {
                    setDurationError("");
                }
            }
            if (event.target.name === "duration" && event.target.value.match(/^\d+$/) == null) {
                setByPassConfirm(true);
                setter("");
            } else {
                setter(event.target.value);
            }
        };
    };

    const expirationTimeFrame = (
        <SRow>
            <SCol span="7" className="pad-bottom-md">
                <label htmlFor="expirationTimeframe" id="lbl-expiration-time-frame">
                    Expiration timeframe
                </label>
                <SSelect
                    name="expirationTimeframe"
                    inputId="expirationTimeframe"
                    aria-labelledby="lbl-expiration-time-frame"
                    noSearch
                    disabled={!ecmRoleAndSubscriptionCheck ? "disabled" : null}
                    value={timeLength}
                    optionsList={timeframes}
                    onS-select={e => onSelectChange("expirationTimeframes", e)}
                />
            </SCol>
            {showMonthDayText ? (
                <SCol span="auto">
                    <label htmlFor="duration" className="required">
                        {isDisplayMonth ? "Months" : "Days"}
                    </label>
                    <input
                        id="duration"
                        name="duration"
                        type="text"
                        className={`small-textbox ${durationError !== "" ? errorTag : noErrorTag}`}
                        onChange={handleChangeInput(setDuration)}
                        value={duration}
                    />
                    {durationError ? (
                        <div className="input-msg">
                            <SIcon name="alert-circle-filled" aria-hidden="true" />
                            <span name="invalid-duration">{durationError}</span>
                        </div>
                    ) : null}
                </SCol>
            ) : null}
        </SRow>
    );

    return (
        <>
            <SDialog
                open={props.certificateFormsDialogOpen}
                id="requestingCertificateForms-dialog"
                className={defaultSource ? "dialogCollapsed" : "dialogExpanded"}
                onS-dismiss={cancelDialog}
                aria-modal="true">
                <div slot="header" id="requestingCertificateForms">
                    {props?.certificateForm?.regionName} exemption for{" "}
                    {props?.certificateForm?.exemptReasonName?.toLowerCase()}
                </div>

                <div slot="body">
                    <SRow>
                        <SCol span="10" className="pad-right-none col-expanded">
                            <div>
                                <div hidden>
                                    <input
                                        name="isSingle"
                                        id="acceptReason"
                                        type="checkbox"
                                        aria-label="This company accepts certificates for this reason"
                                        checked={acceptReason}
                                        onChange={e => handleCheckBoxChange(e)}
                                    />
                                    <label htmlFor="acceptReason" className="margin-top-none">
                                        This company accepts certificates for this reason.
                                    </label>
                                </div>
                            </div>
                            <SRow>
                                <SCol span="7" className="margin-left-none pad-top-xs">
                                    <label htmlFor="certForms" id="lbl-cert-forms">
                                        Certificate form
                                    </label>
                                    <SSelect
                                        name="certForms"
                                        id="certForms"
                                        inputId="certForms"
                                        aria-labelledby="lbl-cert-forms"
                                        className="pad-right-none"
                                        disabled={!ecmRoleAndSubscriptionCheck ? "disabled" : null}
                                        loading={loading}
                                        value={certFormName}
                                        optionsList={getCertFormsOptionList()}
                                        onS-select={e => onSelectChange("certForms", e)}
                                    />
                                </SCol>
                                <SCol span="1" className="margin-top-md pad-top-sm pad-left-none">
                                    <button
                                        className={`link font-bold ${previewClassName}`}
                                        aria-label="View certificate form"
                                        disabled={
                                            certFormName == null ||
                                            loading ||
                                            !ecmRoleAndSubscriptionCheck
                                        }
                                        onClick={renderForm}>
                                        <SIcon
                                            name="zoom-in"
                                            disabled={loading || !ecmRoleAndSubscriptionCheck}
                                            aria-hidden="true"
                                        />
                                    </button>
                                </SCol>
                            </SRow>
                            <SRow className="pad-top-md">
                                <SCol>{expirationTimeFrame}</SCol>
                            </SRow>
                            <SRow className="pad-top-md">
                                <SCol span="7">
                                    <label htmlFor="question">
                                        Qualifying &quot;yes or no&quot; statement for customers
                                    </label>
                                    <textarea
                                        id="question"
                                        name="question"
                                        rows="4"
                                        disabled={!ecmRoleAndSubscriptionCheck}
                                        value={question}
                                        onChange={handleChangeInput(setQuestion)}
                                    />
                                </SCol>
                            </SRow>
                        </SCol>
                        {(isSavingMatrixFile || isLoadingMatrixFile) && !defaultSource ? (
                            <SLoader
                                loading="loading"
                                className="margin-bottom-sm"
                                aria-live="polite"
                            />
                        ) : null}
                        {matrixFile &&
                        !defaultSource &&
                        !(isSavingMatrixFile || isLoadingMatrixFile) ? (
                            <SCol span="2" className="pad-left-none">
                                <FileRender
                                    fileData={matrixFile}
                                    scale={0.3}
                                    imageClass="matrix-file"
                                    documentClass="matrix-pdf-file"
                                />
                            </SCol>
                        ) : null}
                    </SRow>
                </div>
                <div slot="footer">
                    <button
                        className="link font-bold"
                        disabled={
                            loading ||
                            !ecmRoleAndSubscriptionCheck ||
                            isSavingMatrixFile ||
                            !isDefault
                        }
                        onClick={resetMatrixSetting}>
                        <span className="margin-left-xs">Reset to defaults</span>
                    </button>
                    <button
                        className="secondary small"
                        disabled={isSavingMatrixFile}
                        onClick={cancelDialog}>
                        Cancel
                    </button>
                    <button
                        className={saveClassName}
                        disabled={
                            loading ||
                            !ecmRoleAndSubscriptionCheck ||
                            isSavingMatrixFile ||
                            (!defaultSource && !matrixFile)
                        }
                        onClick={() => handleSaveEvent()}>
                        Save
                    </button>
                </div>
            </SDialog>

            <SaveResetConfirmationDialog
                isDeleteCertificateFileDialogOpen={isSaveConfirmationDialogOpen}
                setIsSaveConfirmationDialogOpen={setIsSaveConfirmationDialogOpen}
                title={isUpdate ? "Update" : "Reset"}
                message={`Are you sure you want to ${
                    isUpdate ? "edit" : "reset"
                } the default settings for this region and exempt reason? Doing so will disable any future updates to this information by Avalara.`}
            />

            {isCancelConfirmationDialogOpen ? (
                <CancelConfirmationDialog
                    isCancelConfirmationDialogOpen={isCancelConfirmationDialogOpen}
                    setIsCancelConfirmationDialogOpen={setIsCancelConfirmationDialogOpen}
                />
            ) : null}
        </>
    );
}

export default RequestingCertificateFormsDialog;
