import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { previousDays, buildApiV3UrlWithQuery, buildApiV3Url } from "../shared/Utils";
import { parseGetAllObjResponse, parseSuccessResponse } from "../shared/responseUtils";
import { CRUDOperation, UnkownError } from "../shared/constants";
import toast from "../hooks/toast";
import axios from "../axios";
import getConfig from "../config";

const { hostnames } = getConfig();
const { docLockerClient } = hostnames;

const initialState = {
    isLoading: false,
    page: {
        data: [],
        paginate: {
            pageNumber: 0,
            startIndex: -1,
            endIndex: 0,
            rowsPerPage: 0,
            totalRecords: 0,
        },
    },
    formState: {
        toDate: new Date().toISOString().slice(0, 10),
        fromDate: previousDays(30),
    },
    isExportFailedAPILoading: false,
    isExportFailedAPIDialogOpen: false,
};
const [showToast, hideToast] = toast();

export const failedAPIReportSlice = createSlice({
    name: "failedAPIReport",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setPageToNull: state => {
            state.page = {
                data: [],
                paginate: {
                    pageNumber: 0,
                    startIndex: -1,
                    endIndex: 0,
                    rowsPerPage: 0,
                    totalRecords: 0,
                },
            };
        },
        setFormState: (state, action) => {
            state.formState = action.payload;
        },
        clearFormState: state => {
            state.formState = initialState.formState;
        },
        setIsExportFailedAPIDialogOpen: (state, action) => {
            state.isExportFailedAPIDialogOpen = action.payload;
        },
        setIsLoadingFailedAPIExport: (state, action) => {
            state.isExportFailedAPILoading = action.payload;
        },
    },
});

export const {
    setIsLoading,
    setPage,
    setPageToNull,
    setFormState,
    clearFormState,
    setIsExportFailedAPIDialogOpen,
    setIsLoadingFailedAPIExport,
} = failedAPIReportSlice.actions;

export const selectIsLoading = state => {
    return state.failedAPIReport.isLoading;
};
export const selectPage = state => {
    return state.failedAPIReport.page;
};
export const selectFormState = state => {
    return state.failedAPIReport.formState;
};
export const selectIsExportFailedAPIDialogOpen = state => {
    return state.failedAPIReport.isExportFailedAPIDialogOpen;
};

export const selectIsExportFailedAPILoading = state => {
    return state.failedAPIReport.isExportFAiledAPILoading;
};

export const fetchFailedAPIRecords = (top, skip, paginateDetails) => async (dispatch, getState) => {
    const { isLoading, formState } = getState().failedAPIReport;
    const { rowsPerPage } = getState().session;
    let apiURI;
    top = !top ? rowsPerPage : top;
    const { fromDate, toDate } = formState;
    if (isLoading) {
        return;
    }
    dispatch(setIsLoading(true));
    if (fromDate && toDate) {
        apiURI = buildApiV3UrlWithQuery(
            `failed-apis?from=${formState?.fromDate}&to=${formState?.toDate}`,
            null,
            null,
            top,
            skip
        );
    } else {
        apiURI = buildApiV3UrlWithQuery(`failed-apis`, null, null, top, skip);
    }
    const apiResponse = await axios.get(`${apiURI}`, {
        withCredentials: true,
    });

    if (!parseSuccessResponse(CRUDOperation.ReadAll, apiResponse)?.recordFound) {
        dispatch(setPageToNull());
    } else {
        const readAllResponse = parseGetAllObjResponse(apiResponse);
        const responseWithID = readAllResponse.value.map(item => ({
            ...item,
            id: uuidv4(), // Add your new column with desired value
        }));
        const paginateData = paginateDetails
            ? {
                  pageNumber: paginateDetails?.pageNumber,
                  startIndex: paginateDetails?.startIndex,
                  endIndex: paginateDetails?.endIndex,
                  rowsPerPage: paginateDetails?.rowsPerPage,
                  totalRecords: readAllResponse?.count,
              }
            : {
                  pageNumber: 1,
                  startIndex: 0,
                  endIndex: 19,
                  rowsPerPage: 20,
                  totalRecords: readAllResponse?.count,
              };
        dispatch(
            setPage({
                data: responseWithID,
                paginate: paginateData,
            })
        );
    }

    dispatch(setIsLoading(false));
};

export const postFailedAPIExportAPI = title => async (dispatch, getState) => {
    const { isExportFailedAPILoading, formState } = getState().failedAPIReport;
    const { fromDate, toDate } = formState;
    if (isExportFailedAPILoading) {
        return;
    }

    dispatch(setIsLoadingFailedAPIExport(true));
    const response = await axios.post(
        buildApiV3Url(`export-failed-external-api`),
        {
            title,
            fromDate,
            toDate,
        },
        { withCredentials: true }
    );
    dispatch(setIsExportFailedAPIDialogOpen(false));
    dispatch(setIsLoadingFailedAPIExport(false));

    if (response.status === 200) {
        const baseURL = `https://${docLockerClient}`;
        window.open(`${baseURL}/mylockers/downloads`, "_blank", "noopener,noreferrer");
        showToast(
            "success",
            "We are working on your export. When it's ready, find it in",
            "export-success",
            true,
            "your downloads",
            () => {
                hideToast("export-success");
                window.open(`${baseURL}/mylockers/downloads`, "_blank", "noopener,noreferrer");
            },
            "The time it takes to export data depends on the size of the file and network traffic."
        );
        return;
    }
    showToast("error", UnkownError);
};

export default failedAPIReportSlice.reducer;
