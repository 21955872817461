import errorMessages from "./responseMessages";
import { UnhandledError, AlertType } from "../../shared/constants";
import { errorResponse } from "../../shared/responseUtils";

const filterCondition = (innerMessage, parseError) => {
    let resultString = parseError.code.toLowerCase();
    if (innerMessage.identifierType === "status") {
        resultString = parseError.status.toLowerCase();
    } else if (innerMessage.identifierType === "message") {
        resultString = parseError.message.toLowerCase();
    } else if (innerMessage.identifierType === "instance") {
        resultString = parseError.instance.toLowerCase();
    }
    return resultString;
};

const filterMessage = (innerMessage, parseError) => {
    return innerMessage?.filter(x =>
        x?.identifier.toLowerCase().includes(filterCondition(x, parseError))
    );
};

const responseMessage = (messageType, message, status, identifier) => {
    return {
        responseMessage: {
            messageType,
            message,
            status,
            identifier,
        },
    };
};

export const parseMessage = error => {
    if (error?.response?.data?.Message) {
        return error?.response?.data?.Message;
    }
    if (error?.response?.data?.message) {
        return error?.response?.data?.message;
    }
    if (error?.response?.data[0]?.message) {
        return error?.response?.data[0]?.message;
    }
    if (errorResponse(error)?.message) {
        return errorResponse(error)?.message;
    }
    return null;
};

export const isSuccessStatusCode = status => {
    return status >= 200 && status <= 299;
};

export const getMessage = error => {
    const parseError = errorResponse(error);
    if (parseError?.instance) {
        const messageKeys = Object.keys(errorMessages);
        if (messageKeys) {
            const messageKey = messageKeys.filter(x =>
                parseError?.instance.toLowerCase().includes(x.toLowerCase())
            );

            if (messageKey && messageKey.length > 0) {
                const item = errorMessages[messageKey];
                if (item) {
                    const itemKeys = Object.keys(item);
                    if (itemKeys && error?.config?.method) {
                        const innerMessageKey = itemKeys.filter(x =>
                            error?.config?.method.toLowerCase().includes(x.toLowerCase())
                        );
                        if (innerMessageKey) {
                            const innerMessage = item[innerMessageKey];
                            const actualMessages = filterMessage(innerMessage, parseError);

                            if (actualMessages?.length > 0) {
                                if (actualMessages[0].messageType === AlertType.Error) {
                                    document.querySelector("s-toasts").createToast({
                                        status: actualMessages[0]?.messageType,
                                        text: actualMessages[0]?.message,
                                    });
                                }
                                return responseMessage(
                                    actualMessages[0]?.messageType,
                                    actualMessages[0]?.message,
                                    parseError?.status,
                                    actualMessages[0]?.identifier
                                );
                            }
                            if (
                                parseError?.code &&
                                parseError?.status &&
                                !isSuccessStatusCode(parseError?.status)
                            ) {
                                document.querySelector("s-toasts").createToast({
                                    status: "error",
                                    text: error?.response?.data?.detail || "Something went wrong",
                                });
                            }
                            return responseMessage(
                                UnhandledError,
                                parseError?.message,
                                parseError?.status,
                                parseError?.code
                            );
                        }
                    }
                }
            }
        }
    }
    return null;
};
